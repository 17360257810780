<template>
    <div>
        <b-card>
            <b-row>
                <b-col sm="12">
                    <b-row>
                        <b-col sm="3 ">
                            <label>*Kategori</label>
                            <!-- <b-form-select v-model="t_mkg" class="" id="select-mkg" size="sm">
                <option :value="option">-</option>
                <option value="meteo">Meteorologi</option>
                <option value="klimat">Klimatologi</option>
              </b-form-select> -->
                            <v-select v-model="t_mkg" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="t_mkg_opt" />
                        </b-col>
                        <b-col sm="3 ">
                            <label>*Form</label>
                            <v-select v-model="t_form" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="t_form_opt" />
                            <!-- <b-form-select v-model="t_kabupaten" :options="kabupaten_opt" size="sm" /> -->
                        </b-col>
                        <b-col sm="3">
                            <label>*Group Parameter</label>
                            <v-select v-model="group" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" multiple label="text" :close-on-select="false" :options="group_opt" />
                        </b-col>
                        <b-col sm="2 ">
                            <label>*Balai</label>
                            <v-select v-model="regions" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="regions_opt" />
                            <!-- <b-form-select v-model="regions" :options="regions_opt" size="sm" /> -->
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col sm="3 ">
                            <label>Provinsi</label>
                            <v-select v-model="propinsi" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="propinsi_opt" />
                            <!-- <b-form-select v-model="propinsi" :options="propinsi_opt" size="sm" /> -->
                        </b-col>
                        <b-col sm="3 ">
                            <label>Kota/Kabupaten</label>
                            <v-select v-model="kabupaten" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="kabupaten_opt" />

                            <!-- <b-form-select v-model="kabupaten" :options="kabupaten" size="sm" /> -->
                        </b-col>
                        <b-col sm="3">
                            <label>Stasiun</label>
                            <!-- <b-form-select v-model="stasiun" :options="stasiun_opt" size="sm" /> -->
                            <v-select v-model="stasiun" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" multiple label="text" :close-on-select="false" :options="stasiun_opt" />
                        </b-col>
                        <b-col sm="2 ">
                            <label>*Tanggal Awal-Akhir</label>
                            <flat-pickr v-model="rangeDate" style="height: 40px;" class="form-control" :config="{ mode: 'range' }" />
                        </b-col>
                        <b-col sm="1 pad1" style="margin-top: 25px;">
                            <!-- <label>Preview</label> -->
                            <!-- <b-button  variant="relief-primary" v-on:click="previewGroup">
                <feather-icon icon="EyeIcon" class=" align-middle" v-b-tooltip.hover.v-dark
                title="Preview"/>
              </b-button> -->
                            <b-button variant="relief-primary" v-on:click="showTable"> Fetch </b-button>
                        </b-col>
                    </b-row>
                    <!-- == TABLE== -->
                    <b-card no-body class="border mt-1">
                        <!-- <b-card-header class="p-1 bg-light-primary">
              <b-card-title class="font-medium-1">
                <feather-icon icon="FileIcon" size="16" />
                <span class="align-middle ml-50">Report View</span>
              </b-card-title>
            </b-card-header> -->
                        <b-row class="py-1 text-center justify-content-center" style="font-weight: 600;" v-if="cardCheckbox.length === 0">
                            <label class="mb-0 d-flex align-items-center" style="font-size: 1em;">
                                <feather-icon icon="AlertCircleIcon" size="18" class="bg-primary rounded-circle text-white" style="margin-right: 5px;" />
                                Sesuaikan filter terlebih dahulu untuk memilih parameter
                            </label>
                        </b-row>

                        <b-row class="pt-1 px-1 flex-row justify-content-between" v-if="cardCheckbox.length > 0">
                            <b-row class="px-2 m-0 align-items-center">
                                <span class="align-middle ml-10 text-dark font-weight-bolder my-1">Group Parameter {{ t_form.text }}</span>
                            </b-row>
                            <b-row class="px-2 align-items-center" v-if="cardCheckbox.length > 1">
                                <b-col>
                                    <b-button-group>
                                        <b-button variant="light" v-on:click="checkAllGroups()" size="sm" class="border border-dark" style="border-left-color: black !important;">
                                            <feather-icon icon="CheckSquareIcon" />
                                            Check All Group</b-button
                                        >
                                        <b-button variant="light" v-on:click="uncheckAllGroups()" size="sm" class="border border-dark" style="border-right-color: black !important;">
                                            <feather-icon icon="XSquareIcon" />
                                            Uncheck All Group</b-button
                                        >
                                    </b-button-group>
                                </b-col>
                            </b-row>
                        </b-row>
                        <b-row class="p-1" v-if="cardCheckbox.length > 0">
                            <b-col :cols="col_card" v-for="obj in cardCheckbox" :key="obj.label">
                                <b-card style="border: 2px solid #333333;" id="showcard">
                                    <span class="align-middle ml-10 text-dark font-weight-bolder mb-1">{{ obj.label }}</span>
                                    <b-row class="mt-1" v-if="obj.attr.length > 1">
                                        <!-- <b-col>
                      <b-form-checkbox :value="obj" v-model="checkall"> Checked </b-form-checkbox>
                    </b-col>
                    <b-col>
                      <b-form-checkbox :value="obj" v-model="uncheckall"> Unchecked </b-form-checkbox>
                    </b-col> -->
                                        <b-col>
                                            <b-button-group>
                                                <b-button variant="light" v-on:click="checkAllItems(obj)" size="sm" class="border">
                                                    <feather-icon icon="CheckSquareIcon" />
                                                    Check All</b-button
                                                >
                                                <b-button variant="light" v-on:click="uncheckAllItems(obj)" size="sm" class="border">
                                                    <feather-icon icon="XSquareIcon" />
                                                    Uncheck All</b-button
                                                >
                                            </b-button-group>
                                        </b-col>
                                    </b-row>

                                    <b-form-group label=" " class="font-weight-bolder text-dark">
                                        <b-form-checkbox v-for="item in obj.attr" :key="item.value" v-model="selectedCheck" :value="item.value" name="flavour-3a">
                                            {{ item.text }}
                                        </b-form-checkbox>
                                    </b-form-group>
                                </b-card>
                            </b-col>

                            <!-- <b-col cols="12" class="float-right">
                <b-button v-if="sumbitShow" variant="relief-primary" class="float-right" v-on:click="showTable"> Submit
                </b-button>
              </b-col> -->

                            <!-- popover 2 
              <b-popover ref="popover2" variant="bg-light-secondary" target="popup-2" triggers="click" :show.sync="popoverShow2" placement="auto" container="my-container">
                <template v-slot:title>
                  <div class="d-flex justify-content-between align-items-center">
                    <span>Pilih Pejabat</span>
                    <b-button class="close" variant="white" aria-label="Close" @click="onClosePopup2">
                      <span class="d-inline-block text-white" aria-hidden="true">&times;</span>
                    </b-button>
                  </div>
                </template>

                <div>
                  <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="Nama Pejabat" label-for="nama-pejabat" size="sm">
                    <b-form-select v-model="t_mkg" class="" id="select-mkg" size="sm">
                      <option disable>-</option>
                      <option value="a">M Ridwan</option>
                      <option value="b">Hizkia Edi</option>
                      <option value="c">Wibowo</option>
                    </b-form-select>
                  </b-form-group>
                  <b-button size="sm" variant="primary" @click="onOk2">
                    Ok
                  </b-button>
                </div>
              </b-popover>
              end popover2 -->

                            <!-- select 2 demo -->
                            <b-modal id="modal-export-pdf" title="Ttd Digital" ok-title="Export" cancel-variant="outline-secondary" @ok="exportPdf">
                                <b-form>
                                    <b-form-group label="Nama Pejabat" label-for="vue-select">
                                        <v-select id="pejabat" v-model="pejabat" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="pejabat_opt" />
                                    </b-form-group>
                                    <b-form-group label="Disclaimer" label-for="Disclaimer">
                                        <b-form-textarea id="textarea-default" placeholder="Textarea" rows="8" v-model="dislaimer" />
                                    </b-form-group>
                                    <b-form-group class="bg-gradient-warning p-1">
                                        <b-alert show variant="warning"> Catatan : data yang di export PDF hanya max 10-20 kolom data pertama </b-alert>
                                    </b-form-group>
                                </b-form>
                            </b-modal>
                            <b-modal id="modal-export-pdf-rawpibal" title="Ttd Digital" ok-title="Export" cancel-variant="outline-secondary" @ok="exportPdfRawPibal">
                                <b-form>
                                    <b-form-group label="Nama Pejabat" label-for="vue-select">
                                        <v-select id="pejabat" v-model="pejabat" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="pejabat_opt" />
                                    </b-form-group>
                                    <b-form-group label="Disclaimer" label-for="Disclaimer">
                                        <b-form-textarea id="textarea-default" placeholder="Textarea" rows="8" v-model="dislaimer" />
                                    </b-form-group>
                                    <b-form-group class="bg-gradient-warning p-1">
                                        <b-alert show variant="warning"> Catatan : data yang di export PDF hanya max 10-20 kolom data pertama </b-alert>
                                    </b-form-group>
                                </b-form>
                            </b-modal>
                            <b-modal id="modal-export-pdf-datapibal" title="Ttd Digital" ok-title="Export" cancel-variant="outline-secondary" @ok="exportPdfDataPibal">
                                <b-form>
                                    <b-form-group label="Nama Pejabat" label-for="vue-select">
                                        <v-select id="pejabat" v-model="pejabat" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :options="pejabat_opt" />
                                    </b-form-group>
                                    <b-form-group label="Disclaimer" label-for="Disclaimer">
                                        <b-form-textarea id="textarea-default" placeholder="Textarea" rows="8" v-model="dislaimer" />
                                    </b-form-group>
                                    <b-form-group class="bg-gradient-warning p-1">
                                        <b-alert show variant="warning"> Catatan : data yang di export PDF hanya max 10-20 kolom data pertama </b-alert>
                                    </b-form-group>
                                </b-form>
                            </b-modal>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </b-card>

        <b-card v-if="cardTable">
            <b-row class="d-flex">
                <b-col md="6" class="d-flex">
                    <div class="mb-0 d-flex align-items-center mr-1" style="min-width: 150px;">
                        <label class="text-sm-left mb-0" style="margin-right: 5px;">Per page</label>
                        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
                    </div>
                    <div class="mb-0 d-flex align-items-center mr-1" style="min-width: 160px;">
                        <label class="text-sm-left mb-0" style="margin-right: 5px;">Sort Date</label>
                        <b-form-select id="sortDate" v-model="sortDate" size="sm" :options="sortDateOpt" class="w-50" />
                    </div>
                    <div class="mb-0 d-flex align-items-center" style="min-width: 200px;">
                        <label class="text-sm-left mb-0" style="margin-right: 5px;">Filter</label>
                        <b-input-group size="sm">
                            <b-form-input id="filterInput" size="sm" v-model="filter" type="search" placeholder="Type to Search" />
                            <b-input-group-append>
                                <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </b-col>

                <b-col md="6" class="my-1" v-if="pibaldataform">
                    <download-excel class="btn btn-sm btn-outline-primary float-right" :data="csv_data_pibal" type="csv" :name="datapibalname" style="margin-left: 5px;" header="Data Pibal" title="Data Pibal" :escapeCsv="escapeCsv">
                        Download CSV
                    </download-excel>
                    <download-excel class="btn btn-sm btn-outline-primary float-right" :data="csv_raw_pibal" type="csv" :name="rawpibalname" style="margin-left: 5px;" header="Raw Pibal" title="Raw Pibal" :escapeCsv="escapeCsv">
                        Download CSV Raw Data
                    </download-excel>
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-export-pdf-datapibal style="margin-left: 5px;" variant="outline-primary" size="sm" class="float-right"> Export PDF </b-button>
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-export-pdf-rawpibal style="margin-left: 5px;" variant="outline-primary" size="sm" class="float-right"> Export PDF Raw Data </b-button>
                </b-col>
                <b-col md="6" class="my-1" v-if="ready_export && !pibaldataform">
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-export-pdf variant="outline-primary" style="margin-left: 5px;" size="sm" class="float-right"> Export PDF </b-button>
                    <download-excel class="btn btn-sm btn-outline-primary float-right" :data="items" type="csv" :name="csvfilename" :escapeCsv="escapeCsv" :header="form_name_exp_xls" :title="form_name_exp_xls"> Download CSV </download-excel>
                </b-col>

                <b-col cols="12" class="py-1">
                    <b-table
                        class="table b-table my-table table-striped table-hover table-sm table-responsive text-nowrap text-center"
                        striped
                        hover
                        responsive
                        :bordered="true"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :items="items"
                        :fields="fields"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        show-empty
                        empty-text="Data tidak tersedia untuk filter terpilih"
                    >
                        <template #cell(encoded_synop)="row">
                            <div class="w-100 text-left pl-1">{{ row.item.encoded_synop }}</div>
                        </template>
                        <template #cell(qc_parameter)="row">
                            <div class="w-100 text-left pl-1">{{ row.item.qc_parameter }}</div>
                        </template>
                        <template #cell(sandi_pibal)="row">
                            <div class="w-50 text-left pl-1">{{ row.item.sandi_pibal }}</div>
                        </template>
                        <template #cell(azimuth)="row">
                            <div class="w-50 text-left pl-1">{{ row.item.azimuth }}</div>
                        </template>
                        <template #cell(elevasi)="row">
                            <div class="w-50text-left pl-1">{{ row.item.elevasi }}</div>
                        </template>
                        <template #cell(lapisan)="row">
                            <div class="w-50 text-left pl-1">{{ row.item.lapisan }}</div>
                        </template>
                        <!-- <template #cell(data_timestamp)="data">
              {{ data.value.substr(0, 10) }}
            </template> -->
                        <!-- <template #cell(avatar)="data">
              <b-avatar :src="data.value" />
            </template>
            
            <template #cell(status)="data">
              <b-badge :variant="status[1][data.value]">
                {{ status[0][data.value] }}
              </b-badge>
            </template> -->
                    </b-table>
                </b-col>

                <b-col cols="12">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm" class="mt-1" />
                </b-col>
            </b-row>
        </b-card>
        <div id="divExport" class="divExport">
            <table>
                <!-- {{ JSON.stringify(this.STORE_EXPORT_PIAS)
        }} -->
                <tr>
                    <td v-for="item in items" :key="item"></td>
                </tr>
            </table>
        </div>
        <client-only>
            <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="true"
                :preview-modal="false"
                paginate-elements-by-height="1400"
                :filename="form_name_exp_xls"
                :pdf-quality="2"
                pdf-content-width="1800px"
                :manual-pagination="false"
                pdf-format="legal"
                :pdf-orientation="EXPORT_ORIENTATION"
                @beforeDownload="beforeDownload($event)"
                @progress="onProgress($event)"
                @hasStartedGeneration="hasStartedGeneration()"
                @hasGenerated="hasGenerated($event)"
                ref="html2Pdf"
            >
                <section slot="pdf-content">
                    <div v-if="PDF_SELECTED == 'PIAS'">
                        <PiasPdf :dataPDF="STORE_EXPORT_PIAS" :pejabat_id="pejabat_id" :pejabat_nama="pejabat_nama" :dislaimer="dislaimer" :ttd_digital="ttd_digital" :form_name_exp_pdf="form_name_exp_pdf"></PiasPdf>
                    </div>
                </section>
            </vue-html2pdf>
        </client-only>
        <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
    </div>
</template>

<script>
//import LiquorTree from "liquor-tree";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import PiasPdf from "./pdf/PiasPdf.vue";
import {
    BOverlay,
    BTable,
    BAvatar,
    BBadge,
    BPagination,
    BInputGroupAppend,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormInput,
    BInputGroup,
    BFormSelect,
    BButton,
    BButtonGroup,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormGroup,
    BFormTextarea,
    BPopover,
    BModal,
    VBModal,
    BForm,
    AlertPlugin,
    VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import metadata from "@/api/metadata";
import VueHtml2pdf from "vue-html2pdf";

import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import helper from "@/api/helper";

import outputexport from "@/api/outputdataexport";
import lamapenyinaran from "@/attr/pias/lamapenyinaran";
import aktinograph from "@/attr/pias/aktinograph";
import barograph from "@/attr/pias/barograph";
import fenologi from "@/attr/pias/fenologi";
import intentitashujan from "@/attr/pias/intentitashujan";
import kelembabantanah from "@/attr/pias/kelembabantanah";
import perawanan from "@/attr/pias/perawanan";
import thermohigrograph from "@/attr/pias/thermohigrograph";
import formhujan from "@/attr/pias/formhujan";

import fklim from "@/attr/fdih/fklim";
import agm1a from "@/attr/fdih/agm1a";
import agm1b from "@/attr/fdih/agm1b";
import gunbellani from "@/attr/fdih/gunbellani";
import iklimmikro from "@/attr/fdih/iklimmikro";
import lysimeter from "@/attr/fdih/lysimeter";
import pichepenguapan from "@/attr/fdih/pichepenguapan";
import psycrometerassman from "@/attr/fdih/psycrometerassman";
import suhutanah from "@/attr/fdih/suhutanah";
import oppenguapan from "@/attr/fdih/oppenguapan";

import me45 from "@/attr/meteo/me45";
import me48 from "@/attr/meteo/me48";
import sinoptik from "@/attr/meteo/sinop";

import metar from "@/attr/meteo/metar";
import speci from "@/attr/meteo/speci";
import metarspecy from "@/attr/meteo/metarspecy";

import pibal from "@/attr/meteo/pibal";

import kah from "@/attr/ku/kah";
import spm from "@/attr/ku/spm";
import so2no2 from "@/attr/ku/so2no2";
import aerosol from "@/attr/ku/aerosol";
// ALIAS
import aliasAgm1a from "@/aliasPdf/aliasAgm1a.json";
import aliasAgm1b from "@/aliasPdf/aliasAgm1b.json";
import aliasAktinograph from "@/aliasPdf/aliasAktinograph.json";
import aliasBarograph from "@/aliasPdf/aliasBarograph.json";
import aliasFenologi from "@/aliasPdf/aliasFenologi.json";
import aliasFklim71 from "@/aliasPdf/aliasFklim71.json";
import aliasFormHujan from "@/aliasPdf/aliasFormHujan.json";
import aliasFormHujanHelman from "@/aliasPdf/aliasIntensitasHujanHelman.json";
import aliasFormKelembapanTanah from "@/aliasPdf/aliasKelembapanTanah.json";
import aliasFormPerawanan from "@/aliasPdf/aliasFormPerawanan.json";
import aliasGunBellani from "@/aliasPdf/aliasGunBellani.json";
import aliasIklimMikro from "@/aliasPdf/aliasIklimMikro.json";
import aliasLamaPenyinaran from "@/aliasPdf/aliasLamaPenyinaran.json";
import aliasLysimeter from "@/aliasPdf/aliasLysimeter.json";
import aliasOpPenguapan from "@/aliasPdf/aliasOpPenguapan.json";

// import dataPibal from "./DataAsli/dataPibal.json";
import aliasPibal from "@/aliasPdf/aliasPibal.json";
import aliasPichePenguapan from "@/aliasPdf/aliasPichePenguapan.json";
import aliasPsychrometerAssman from "@/aliasPdf/aliasPsychrometerAssman.json";
import aliasSuhuTanah from "@/aliasPdf/aliasSuhuTanah.json";
import aliasThermohigroghraph from "@/aliasPdf/aliasThermohigroghraph.json";

// END ALIAS

import JsPDFAutotable from "jspdf-autotable";
import jsPDF from "jspdf";

export default {
    components: {
        AlertPlugin,
        BOverlay,
        PiasPdf,
        VueHtml2pdf,
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardTitle,
        vSelect,
        jsPDF,
        JsPDFAutotable,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BFormSelect,
        BButton,
        BButtonGroup,
        flatPickr,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormDatepicker,
        BTable,
        BAvatar,
        BBadge,
        BPagination,
        BInputGroupAppend,
        BPopover,
        BFormTextarea,
        BModal,
        BForm,
        VBTooltip,
    },
    directives: {
        "b-modal": VBModal,
        "b-tooltip": VBTooltip,
        Ripple,
    },
    data() {
        return {
            todos: [
                { title: "title 1", description: "description 1" },
                { title: "title 2", description: "description 2" },
                { title: "title 3", description: "description 3" },
                { title: "title 4", description: "description 4" },
            ],
            rawpibalname: "",
            datapibalname: "",
            escapeCsv: false,
            advancedOptions: {
                header: true,
            },
            list_allias_parameters: null,
            is_bmkg_entry: "",
            is_balai_selected: "",
            is_prov_selected: "",
            is_kab_selected: "",
            col_card: 3,
            temp_cols: 3,
            sumbitShow: false,
            pibaldataform: false,
            ttd_digital: "",
            row_esign: {},
            dislaimer: "",
            pejabat: "",
            pejabat_opt: [],
            showLoading: false,
            pejabat_nama: "",
            pejabat_id: "",
            pejabat_jabatan: "",
            EXPORT_ORIENTATION: "landscape",
            ready_export: false,
            STORE_EXPORT_PIAS: [],
            PDF_SELECTED: "",
            csvfilename: "No_data.csv",
            checkall: [],
            uncheckall: [],
            cardTable: true,
            group: "",
            group_opt: [],
            regions: "",
            regions_opt: [],
            propinsi: "",
            propinsi_opt: [],
            kabupaten: "",
            kabupaten_opt: [],
            stasiun: "",
            stasiun_opt: [],
            t_form: "",
            t_form_opt: [],
            t_formReset: false,
            t_mkg: "",
            t_mkg_opt: [
                { value: "meteo", text: "Meteorologi" },
                { value: "klimat", text: "Klimatologi" },
            ],
            t_from_m: [
                { value: "", text: "Pilih Form" },
                { value: "MetarSpeci", text: "Metar/Speci" },
                { value: "Pibal", text: "Pibal" },
                { value: "sinoptik", text: "Sinop" },

                // { value: "metar", text: "Metar" },
                // { value: "speci", text: "Speci" },
            ],
            t_from_k: [
                { value: "", text: "Pilih Form" },
                { value: "Aerosol", text: "Aerosol" },
                { value: "Agm1a", text: "Agm1a" },
                { value: "Agm1b", text: "Agm1b" },
                { value: "Aktinograph", text: "Aktinograph" },
                { value: "Barograph", text: "Barograph" },
                { value: "Fenologi", text: "Fenologi" },
                { value: "Fklim", text: "Fklim 71" },
                { value: "FormHujan", text: "Form Hujan" },
                { value: "FormPerawanan", text: "Form Perawanan" },
                { value: "Gunbellani", text: "Gunbellani" },
                { value: "IklimMikro", text: "Iklim Mikro" },
                { value: "HujanHellman", text: "Intensitas Hujan(Hellman)" },
                { value: "Kah", text: "Kimia Air Hujan" },
                { value: "KelembabanTanah", text: "Kelembaban Tanah" },
                { value: "LamaPenyinaran", text: "Lama Penyinaran" },
                { value: "Lysimeter", text: "Lysimeter" },
                { value: "OpPenguapan", text: "OP Penguapan" },
                { value: "PichePenguapan", text: "Piche Penguapan" },
                { value: "PsycrometerAssman", text: "Psychrometer Assman" },
                { value: "So2no2", text: "SO2NO2" },

                { value: "SuhuTanah", text: "Suhu Tanah" },
                { value: "Spm", text: "Suspended Particulate Matter" },
                { value: "Thermohigograph", text: "Thermohigrograph" },
            ],
            t_from_g: [],

            rangeDate: null,
            dir: "ltr",
            selected: "",
            selected1: "",
            option: [{ title: "Square" }, { title: "Rectangle" }, { title: "Rombo" }, { title: "Romboid" }],
            option1: [{ title: "Square" }, { title: "Rectangle" }, { title: "Rombo" }, { title: "Romboid" }],
            //checkbok
            selectedCheck: [],
            // optionsCheck: [
            //   { text: "Pengenal Data Angin", value: "Pengenal Data Angin" },
            //   { text: "Arah Angin", value: "Arah Angin" },
            //   { text: "Kecepatan Angin", value: "Kecepatan Angin" },
            //   { text: "Angin", value: "Angin" },
            // ],
            cardCheckbox: [],
            arr_group: [],
            g_lamapenyinaran: [],
            g_aktinograph: [],
            g_thermohigrograph: [],
            g_intensitashujan: [],
            g_barograph: [],
            g_kelambabantanah: [],
            g_all: {},

            type_object: "",
            stasiun_arr: [],

            perPage: 20,
            pageOptions: [5, 10, 20, 50],
            sortDate: "Asc",
            sortDateOpt: ["Asc", "Desc"],
            totalRows: 1,
            currentPage: 1,
            filter: null,
            filterOn: [],
            fields: [
                // {
                //   key: 'id', label: 'Id',
                // },
                // {
                //   key: 'avatar', label: 'Avatar',
                // },
                // { key: 'full_name', label: 'Full Name', sortable: true },
                // { key: 'post', label: 'Post', sortable: true },
                // 'email',
                // 'city',
                // 'start_date',
                // 'salary',
                // 'age',
                // 'experience',
                // { key: 'status', label: 'Status', sortable: true },
            ],
            items: [
                // {
                //   id: 1,
                //   // eslint-disable-next-line global-require
                //   avatar: require('@/assets/images/avatars/10-small.png'),
                //   full_name: "Korrie O'Crevy",
                //   post: 'Nuclear Power Engineer',
                //   email: 'kocrevy0@thetimes.co.uk',
                //   city: 'Krasnosilka',
                //   start_date: '09/23/2016',
                //   salary: '$23896.35',
                //   age: '61',
                //   experience: '1 Year',
                //   status: 2,
                // },
            ],
            status: [
                {
                    1: "Current",
                    2: "Professional",
                    3: "Rejected",
                    4: "Resigned",
                    5: "Applied",
                },
                {
                    1: "light-primary",
                    2: "light-success",
                    3: "light-danger",
                    4: "light-warning",
                    5: "light-info",
                },
            ],
            th_export: [],
            csv_raw_pibal: [],
            csv_data_pibal: [],
            form_name_exp_pdf: "",
            form_name_exp_xls: "",

            myAliasAgm1a: aliasAgm1a[0],
            myAliasAgm1b: aliasAgm1b[0],
            myAliasAktinograph: aliasAktinograph[0],
            myAliasBarograph: aliasBarograph[0],
            myAliasFenologi: aliasFenologi[0],
            myAliasFklim71: aliasFklim71[0],
            myAliasFormHujan: aliasFormHujan[0],
            myAliasFormHujanHelman: aliasFormHujanHelman[0],
            myAliasFormKelembapanTanah: aliasFormKelembapanTanah[0],
            myAliasFormPerawanan: aliasFormPerawanan[0],
            myAliasGunBellani: aliasGunBellani[0],
            myAliasIklimMikro: aliasIklimMikro[0],
            myAliasLamaPenyinaran: aliasLamaPenyinaran[0],
            myAliasLysimeter: aliasLysimeter[0],
            myAliasOpPenguapan: aliasOpPenguapan[0],
            myAliasPibal: aliasPibal[0],
            myAliasPichePenguapan: aliasPichePenguapan[0],
            myAliasPsychrometerAssman: aliasPsychrometerAssman[0],
            myAliasSuhuTanah: aliasSuhuTanah[0],
            myAliasThermohigroghraph: aliasThermohigroghraph[0],

            dataAlias: [],
            dataAsal: [],
            dataFinal: [],
        };
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
        },
    },
    mounted() {
        this.getAllias();
        this.totalRows = this.items.length;
        this.getListPejabat();
        this.listBalai();

        // this.g_lamapenyinaran = lamapenyinaran.getAttr()
        // this.g_aktinograph = aktinograph.getAttr()
        // this.g_thermohigrograph = thermohigrograph.getAttr()
        // this.g_intensitashujan = intentitashujan.getAttr()
        // this.g_barograph = barograph.getAttr()
        // this.g_kelambabantanah = kelembabantanah.getAttr()
        this.g_all = {
            LamaPenyinaran: lamapenyinaran.getAttr(),
            Aktinograph: aktinograph.getAttr(),
            Thermohigograph: thermohigrograph.getAttr(),
            HujanHellman: intentitashujan.getAttr(),
            Barograph: barograph.getAttr(),
            KelembabanTanah: kelembabantanah.getAttr(),
            FormPerawanan: perawanan.getAttr(),
            Fenologi: fenologi.getAttr(),
            FormHujan: formhujan.getAttr(),

            Fklim: fklim.getAttr(),
            Agm1a: agm1a.getAttr(),
            Agm1b: agm1b.getAttr(),
            Gunbellani: gunbellani.getAttr(),
            IklimMikro: iklimmikro.getAttr(),
            Lysimeter: lysimeter.getAttr(),
            PichePenguapan: pichepenguapan.getAttr(),
            PsycrometerAssman: psycrometerassman.getAttr(),
            SuhuTanah: suhutanah.getAttr(),
            OpPenguapan: oppenguapan.getAttr(),
            me45: me45.getAttr(),
            me48: me48.getAttr(),
            metar: metar.getAttr(),
            speci: speci.getAttr(),

            sinoptik: sinoptik.getAttr(),
            MetarSpeci: metarspecy.getAttr(),

            Pibal: pibal.getAttr(),

            Aerosol: aerosol.getAttr(),
            Kah: kah.getAttr(),
            So2no2: so2no2.getAttr(),
            Spm: spm.getAttr(),
        };
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        two_digits_decimal(val) {
            // console.log("value decimal : ",val);
            if (val == null || val == "") {
                return "";
            } else {
                return parseFloat(val).toFixed(2);
            }
        },
        async getAllias() {
            let obj_data = await metadata.getAllias("1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22");
            // console.log("allias : ",obj_data)

            const list_attr = {};
            for (var obj in obj_data) {
                var arr_data = obj_data[obj];
                for (var arr in arr_data) {
                    var data = arr_data[arr];
                    for (var i in data) {
                        var parameter = data[i].parameter;
                        var alias = data[i].alias;
                        var parameter_entry = data[i].parameter_entry;
                        list_attr[parameter] = {
                            alias_parameter: alias,
                            parameter_entry: parameter_entry,
                        };
                    }
                }
            }
            this.list_allias_parameters = list_attr;
            // console.log("obj param : ", list_attr);
        },
        async showTable() {
            this.sortDate = "Asc";
            // console.log("stasiun : ",this.stasiun_opt)
            // return
            var stasiun_opt_temp = this.stasiun_opt;
            var stasiun_tmp = [];
            stasiun_opt_temp.forEach((o) => {
                if (o.value !== "") stasiun_tmp.push(o.value);
            });
            if (this.regions == null || this.propinsi == null || this.kabupaten == null) {
                this.$swal({
                    title: "ERROR",
                    text: "Silahkan Lengkapi Filter",
                    icon: "error",
                    customClass: {
                        confirmButton: "btn btn-danger",
                    },
                    buttonsStyling: false,
                });
                return;
            }
            this.pibaldataform = false;
            this.stasiun_arr = [];
            this.STORE_EXPORT_PIAS = [];
            // console.log("array : ",this.selectedCheck)
            // console.log("region : ",this.regions.value);
            // console.log("propinsi : ",this.propinsi);
            // console.log("kabupaten : ",this.kabupaten);
            let reg_selected = this.regions.value;
            let prov_selected = this.propinsi.value;
            if (prov_selected == "") {
                prov_selected = null;
            }
            let kab_selected = this.kabupaten.value;
            if (kab_selected == "") {
                kab_selected = null;
            }
            if (this.rangeDate == null || reg_selected == "") {
                this.$swal({
                    title: "ERROR",
                    text: "Silahkan Lengkapi Filter",
                    icon: "error",
                    customClass: {
                        confirmButton: "btn btn-danger",
                    },
                    buttonsStyling: false,
                });
                return;
            }
            if (this.selectedCheck == "" || this.selectedCheck == null) {
                this.$swal({
                    title: "ERROR",
                    text: "Silahkan pilih parameter yang diinginkan",
                    icon: "error",
                    customClass: {
                        confirmButton: "btn btn-danger",
                    },
                    buttonsStyling: false,
                });
                return;
            }
            var rangeDate_arr = this.rangeDate.split("to");
            // console.log("date", rangeDate_arr[1]);
            var date_gte = "";
            var date_lte = "";
            var twoDate = false;
            if (rangeDate_arr[1] == undefined) {
                // this.$swal({
                //   title: "ERROR",
                //   text: "Silahkan Isi Range Date dengan benar",
                //   icon: "error",
                //   customClass: {
                //     confirmButton: "btn btn-danger",
                //   },
                //   buttonsStyling: false,
                // });
                // return;

                date_gte = rangeDate_arr[0].trim() + "T00:00";
                date_lte = rangeDate_arr[0].trim() + "T23:59";
            } else {
                twoDate = true;
                date_gte = rangeDate_arr[0].trim() + "T00:00";
                date_lte = rangeDate_arr[1].trim() + "T23:59";
            }
            this.showLoading = true;
            this.csvfilename = this.type_object + " " + this.rangeDate + ".csv";
            this.rawpibalname = "Raw Pibal " + this.rangeDate + ".csv";
            this.datapibalname = "Data Pibal " + this.rangeDate + ".csv";

            for (var i in this.stasiun) {
                if (this.stasiun[i].value !== "") {
                    this.stasiun_arr.push(this.stasiun[i].value);
                }
            }
            var _statiun = this.stasiun_arr.toString();
            // console.log(_statiun);
            if (_statiun == "") {
                var temp_s = stasiun_tmp.toString();
                if (temp_s == "") {
                    _statiun = null;
                } else {
                    _statiun = temp_s;
                }
            }

            var _metadata_arr = [];
            _metadata_arr = this.selectedCheck;
            //  console.log("met arr : ",_metadata_arr);
            //   console.log("selectedCheck : ",this.selectedCheck);
            var _metadata = "";
            _metadata = "station_id,station_name,data_timestamp,alias_station_id,source_data," + _metadata_arr.toString();
            // _metadata = "station_id,station_name,data_timestamp,current_latitude,current_longitude," + _metadata_arr.toString();

            var _headTable = _metadata.split(",");
            // console.log(_metadata);
            // console.log(_headTable);
            // this.fields = _headTable
            this.ready_export = true;
            this.items = [];
            var currentFrom = this.type_object;
            if (this.type_object == "me45" || this.type_object == "me48" || this.type_object == "sinoptik") {
                currentFrom = "Sinoptik";
            } else if (this.type_object == "metar" || this.type_object == "speci" || this.type_object == "MetarSpeci") {
                currentFrom = "MetarSpeci";
            }

            if (currentFrom == "FormHujan") {
                var metahujanharian = "station_id,alias_station_id,station_name,current_latitude,current_longitude,rr_timestamp," + _metadata_arr.toString() + ",qc_flag,qc_histories";
                var arr_metahujanharian = metahujanharian.split(",");
                try {
                    const { data } = await outputexport.getRawDataFormHujanHarian(currentFrom, date_gte, date_lte, _statiun, metahujanharian, reg_selected, prov_selected, kab_selected);
                    var tempData = [];
                    var nonya = 1;
                    for (var i in data) {
                        var row = data[i];
                        var obj = {};
                        if (arr_metahujanharian.indexOf("rr_phk_mm") == -1 && arr_metahujanharian.indexOf("rr_das_mm") != -1) {
                            var TGL = row.rr_timestamp.substr(8, 2);
                            if (TGL == "01" || TGL == "11" || TGL == "21") {
                            } else {
                                continue;
                            }
                        }
                        obj["station_id"] = row.alias_station_id;
                        obj["station_name"] = row.station_name;
                        obj["latitude"] = row.current_latitude;
                        obj["longitude"] = row.current_longitude;
                        obj["data_timestamp"] = row.rr_timestamp.substr(0, 10);
                        if (arr_metahujanharian.indexOf("rr_phk_mm") != -1) {
                            obj["rr_phk_mm"] = row.rr_phk_mm;
                        }
                        if (arr_metahujanharian.indexOf("rr_das_mm") != -1) {
                            obj["rr_das_mm"] = row.rr_das_mm;
                        }
                        if (arr_metahujanharian.indexOf("observer_id") != -1) {
                            obj["observer_id"] = row.observer_id;
                        }
                        if (arr_metahujanharian.indexOf("observer_name") != -1) {
                            obj["observer_name"] = row.observer_name;
                        }
                        var data_qc = row.qc_flag;
                        obj["qc_flag"] = data_qc;
                        obj["qc_parameter"] = "";
                        if (data_qc == 1) {
                            var qc_histories = row.qc_histories;
                            // console.log("qc his : ",qc_histories);
                            if (qc_histories !== null) {
                                let JsonQc = JSON.parse(qc_histories);
                                let Qc_His = JsonQc.after;
                                var Log_QC_His = "";
                                for (var i_qc in Qc_His) {
                                    Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                                }
                                let len = Log_QC_His.length;
                                let log = Log_QC_His.substring(0, len - 1);
                                obj["qc_parameter"] = log;
                            }

                            // console.log("MASUK_QC: ",tgl[field]);
                        }
                        // console.log("obj : ",obj)
                        tempData.push(obj);
                        // console.log("Keluar Idx: ",tgl[field]);
                    }
                    // console.log("tempData : ", tempData);

                    this.items = tempData;
                    this.STORE_EXPORT_PIAS = tempData;
                    this.totalRows = this.items.length;
                    this.showLoading = false;
                    // console.log("this.items :",this.items);
                    // console.log(" this.totalRows : ", this.totalRows);
                } catch (e) {
                    // console.log(e);
                    this.showLoading = false;
                }
            } else if (
                currentFrom == "Fklim" ||
                currentFrom == "Agm1a" ||
                currentFrom == "Agm1b" ||
                currentFrom == "Gunbellani" ||
                currentFrom == "IklimMikro" ||
                currentFrom == "Lysimeter" ||
                currentFrom == "PichePenguapan" ||
                currentFrom == "PsycrometerAssman" ||
                currentFrom == "SuhuTanah" ||
                currentFrom == "OpPenguapan"
            ) {
                try {
                    if (currentFrom == "OpPenguapan") {
                        _metadata = _metadata + ",qc_flag_op_penguapan,qc_histories_op_penguapan";
                    }
                    if (currentFrom == "PichePenguapan") {
                        _metadata = _metadata + ",qc_flag_piche_penguapan,qc_histories_piche_penguapan";
                    }
                    if (currentFrom == "Fklim") {
                        _metadata = _metadata + ",qc_flag_fklim,qc_histories_fklim";
                    }
                    if (currentFrom == "Agm1a") {
                        _metadata = _metadata + ",qc_flag_agm1a,qc_histories_agm1a";
                    }
                    if (currentFrom == "Agm1b") {
                        _metadata = _metadata + ",qc_flag_agm1b,qc_histories_agm1b";
                    }
                    if (currentFrom == "SuhuTanah") {
                        _metadata = _metadata + ",qc_flag_suhu_tanah,qc_histories_suhu_tanah";
                    }
                    if (currentFrom == "PsycrometerAssman") {
                        _metadata = _metadata + ",qc_flag_psychrometer_assman,qc_histories_psychrometer_assman";
                    }
                    if (currentFrom == "Gunbellani") {
                        _metadata = _metadata + ",qc_flag_gunbellani,qc_histories_gunbellani";
                    }
                    if (currentFrom == "IklimMikro") {
                        _metadata = _metadata + ",qc_flag_iklim_mikro,qc_histories_iklim_mikro";
                    }
                    const { data } = await outputexport.getRawDataFdih(currentFrom, date_gte, date_lte, _statiun, _metadata, reg_selected, prov_selected, kab_selected);
                    var tempData = [];
                    for (var obj in data) {
                        var itemObj = {};

                        // itemObj["station_id"] = data[obj].station_id;
                        itemObj["station_id"] = data[obj].alias_station_id;
                        itemObj["station_name"] = data[obj].station_name;

                        itemObj["current_latitude"] = data[obj].current_latitude;
                        itemObj["current_longitude"] = data[obj].current_longitude;
                        itemObj["data_timestamp"] = data[obj].data_timestamp.substr(0, 10);
                        // itemObj["id"] = data[obj].id;
                        // console.log("index of  rh_avg_4 = ",_metadata_arr.indexOf("rh_avg_4"))
                        //iklim mikro
                        if (_metadata_arr.indexOf("observer_id") != "-1") {
                            itemObj["observer_id"] = data[obj].observer_id;
                        }
                        if (_metadata_arr.indexOf("observer_name") != "-1") {
                            itemObj["observer_name"] = data[obj].observer_name;
                        }
                        if (_metadata_arr.indexOf("rh_avg_4") != "-1") {
                            itemObj["rh_avg_4"] = data[obj].rh_avg_4 == 9999 ? data[obj].rh_avg_4 : this.two_digits_decimal(data[obj].rh_avg_4);
                        }
                        if (_metadata_arr.indexOf("rh_avg_7") != "-1") {
                            itemObj["rh_avg_7"] = data[obj].rh_avg_7 == 9999 ? data[obj].rh_avg_7 : this.two_digits_decimal(data[obj].rh_avg_7);
                        }
                        if (_metadata_arr.indexOf("rh_avg_10") != "-1") {
                            itemObj["rh_avg_10"] = data[obj].rh_avg_10 == 9999 ? data[obj].rh_avg_10 : this.two_digits_decimal(data[obj].rh_avg_10);
                        }
                        if (_metadata_arr.indexOf("tbk_avg_4") != "-1") {
                            itemObj["tbk_avg_4"] = data[obj].tbk_avg_4 == 9999 ? data[obj].tbk_avg_4 : this.two_digits_decimal(data[obj].tbk_avg_4);
                        }
                        if (_metadata_arr.indexOf("tbk_avg_7") != "-1") {
                            itemObj["tbk_avg_7"] = data[obj].tbk_avg_7 == 9999 ? data[obj].tbk_avg_7 : this.two_digits_decimal(data[obj].tbk_avg_7);
                        }
                        if (_metadata_arr.indexOf("tbk_avg_10") != "-1") {
                            itemObj["tbk_avg_10"] = data[obj].tbk_avg_10 == 9999 ? data[obj].tbk_avg_10 : this.two_digits_decimal(data[obj].tbk_avg_10);
                        }

                        if (_metadata_arr.indexOf("ws_avg_4_0713") != "-1") {
                            itemObj["ws_avg_4_0713"] = data[obj].ws_avg_4_0713 == 9999 ? data[obj].ws_avg_4_0713 : this.two_digits_decimal(data[obj].ws_avg_4_0713);
                        }
                        if (_metadata_arr.indexOf("ws_avg_4_1318") != "-1") {
                            itemObj["ws_avg_4_1318"] = data[obj].ws_avg_4_1318 == 9999 ? data[obj].ws_avg_4_1318 : this.two_digits_decimal(data[obj].ws_avg_4_1318);
                        }
                        if (_metadata_arr.indexOf("ws_avg_4_1807") != "-1") {
                            itemObj["ws_avg_4_1807"] = data[obj].ws_avg_4_1807 == 9999 ? data[obj].ws_avg_4_1807 : this.two_digits_decimal(data[obj].ws_avg_4_1807);
                        }

                        if (_metadata_arr.indexOf("ws_avg_7_0713") != "-1") {
                            itemObj["ws_avg_7_0713"] = data[obj].ws_avg_7_0713 == 9999 ? data[obj].ws_avg_7_0713 : this.two_digits_decimal(data[obj].ws_avg_7_0713);
                        }
                        if (_metadata_arr.indexOf("ws_avg_7_1318") != "-1") {
                            itemObj["ws_avg_7_1318"] = data[obj].ws_avg_7_1318 == 9999 ? data[obj].ws_avg_7_1318 : this.two_digits_decimal(data[obj].ws_avg_7_1318);
                        }
                        if (_metadata_arr.indexOf("ws_avg_7_1807") != "-1") {
                            itemObj["ws_avg_7_1807"] = data[obj].ws_avg_7_1807 == 9999 ? data[obj].ws_avg_7_1807 : this.two_digits_decimal(data[obj].ws_avg_7_1807);
                        }

                        if (_metadata_arr.indexOf("ws_avg_10_0713") != "-1") {
                            itemObj["ws_avg_10_0713"] = data[obj].ws_avg_10_0713 == 9999 ? data[obj].ws_avg_10_0713 : this.two_digits_decimal(data[obj].ws_avg_10_0713);
                        }
                        if (_metadata_arr.indexOf("ws_avg_10_1318") != "-1") {
                            itemObj["ws_avg_10_1318"] = data[obj].ws_avg_10_1318 == 9999 ? data[obj].ws_avg_10_1318 : this.two_digits_decimal(data[obj].ws_avg_10_1318);
                        }
                        if (_metadata_arr.indexOf("ws_avg_10_1807") != "-1") {
                            itemObj["ws_avg_10_1807"] = data[obj].ws_avg_10_1807 == 9999 ? data[obj].ws_avg_10_1807 : this.two_digits_decimal(data[obj].ws_avg_10_1807);
                        }

                        // Fklim71
                        if (_metadata_arr.indexOf("tbk_avg") != "-1") {
                            itemObj["tbk_avg"] = data[obj].tbk_avg == 9999 ? data[obj].tbk_avg : this.two_digits_decimal(data[obj].tbk_avg);
                        }
                        if (_metadata_arr.indexOf("rh_avg") != "-1") {
                            itemObj["rh_avg"] = data[obj].rh_avg == 9999 ? data[obj].rh_avg : this.two_digits_decimal(data[obj].rh_avg);
                        }
                        if (_metadata_arr.indexOf("ff_avg_km_jm") != "-1") {
                            itemObj["ff_avg_km_jm"] = this.two_digits_decimal(data[obj].ff_avg_km_jm);
                        }
                        if (_metadata_arr.indexOf("tbk_avg_10") != "-1") {
                            itemObj["tbk_avg_10"] = data[obj].tbk_avg_10 == 9999 ? data[obj].tbk_avg_10 : this.two_digits_decimal(data[obj].tbk_avg_10);
                        }
                        if (_metadata_arr.indexOf("wd_cardinal") != "-1") {
                            itemObj["wd_cardinal"] = data[obj].wd_cardinal;
                        }
                        if (_metadata_arr.indexOf("ff_max") != "-1") {
                            itemObj["ff_max"] = data[obj].ff_max;
                        }
                        if (_metadata_arr.indexOf("wd_ff_max") != "-1") {
                            itemObj["wd_ff_max"] = data[obj].wd_ff_max;
                        }

                        //AGM1b
                        if (_metadata_arr.indexOf("ws_avg_1b_0c5_1") != "-1") {
                            itemObj["ws_avg_1b_0c5_1"] = data[obj].ws_avg_1b_0c5_1 == 9999 ? data[obj].ws_avg_1b_0c5_1 : this.two_digits_decimal(data[obj].ws_avg_1b_0c5_1);
                            // itemObj["ws_avg_1b_0c5_0730"] = this.two_digits_decimal(data[obj].ws_avg_1b_0c5_1);
                        }
                        if (_metadata_arr.indexOf("ws_avg_1b_0c5_2") != "-1") {
                            itemObj["ws_avg_1b_0c5_2"] = data[obj].ws_avg_1b_0c5_2 == 9999 ? data[obj].ws_avg_1b_0c5_2 : this.two_digits_decimal(data[obj].ws_avg_1b_0c5_2);
                            // itemObj["ws_avg_1b_0c5_1330"] = this.two_digits_decimal(data[obj].ws_avg_1b_0c5_2);
                        }
                        if (_metadata_arr.indexOf("ws_avg_1b_0c5_3") != "-1") {
                            itemObj["ws_avg_1b_0c5_3"] = data[obj].ws_avg_1b_0c5_3 == 9999 ? data[obj].ws_avg_1b_0c5_3 : this.two_digits_decimal(data[obj].ws_avg_1b_0c5_3);
                            // itemObj["ws_avg_1b_0c5_1730"] = this.two_digits_decimal(data[obj].ws_avg_1b_0c5_3);
                        }

                        if (_metadata_arr.indexOf("sum_ch_1b") != "-1") {
                            itemObj["sum_ch_1b"] = this.two_digits_decimal(data[obj].sum_ch_1b);
                        }
                        if (_metadata_arr.indexOf("sum_eva_1b") != "-1") {
                            itemObj["sum_eva_1b"] = this.two_digits_decimal(data[obj].sum_eva_1b);
                        }
                        if (_metadata_arr.indexOf("ket_1b") != "-1") {
                            itemObj["ket_1b"] = data[obj].ket_1b;
                        }

                        var items_0700ws = data[obj].m_0700ws;
                        if (items_0700ws != undefined || items_0700ws != null) {
                            for (var i_meta in _metadata_arr) {
                                var tmp_field = _metadata_arr[i_meta].split("[");
                                if (tmp_field.length > 1) {
                                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                                    // console.log("fieldnya : ",fieldnya)
                                    var ws = tmp_field[0];
                                    if (ws == "m_0700ws") {
                                        var f_data = items_0700ws[fieldnya];
                                        itemObj[fieldnya] = "";
                                        if (f_data != undefined) {
                                            itemObj[fieldnya] = f_data == "null" ? "" : f_data;
                                        }
                                    }
                                }
                            }
                        } else if (items_0700ws == null) {
                            for (var i_meta in _metadata_arr) {
                                var tmp_field = _metadata_arr[i_meta].split("[");
                                if (tmp_field.length > 1) {
                                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                                    var ws = tmp_field[0];
                                    if (ws == "m_0700ws") {
                                        itemObj[fieldnya] = "";
                                    }
                                }
                            }
                        }
                        var items_0730ws = data[obj].m_0730ws;
                        if (items_0730ws != undefined || items_0730ws != null) {
                            for (var i_meta in _metadata_arr) {
                                var tmp_field = _metadata_arr[i_meta].split("[");
                                if (tmp_field.length > 1) {
                                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                                    var ws = tmp_field[0];
                                    if (ws == "m_0730ws") {
                                        var f_data = items_0730ws[fieldnya];
                                        itemObj[fieldnya] = "";
                                        if (f_data != undefined) {
                                            itemObj[fieldnya] = f_data == "null" ? "" : f_data;
                                        }
                                    }
                                }
                            }
                        } else if (items_0730ws == null) {
                            for (var i_meta in _metadata_arr) {
                                var tmp_field = _metadata_arr[i_meta].split("[");
                                if (tmp_field.length > 1) {
                                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                                    var ws = tmp_field[0];
                                    if (ws == "m_0730ws") {
                                        itemObj[fieldnya] = "";
                                    }
                                }
                            }
                        }
                        var items_1300ws = data[obj].m_1300ws;
                        if (items_1300ws != undefined || items_1300ws != null) {
                            for (var i_meta in _metadata_arr) {
                                var tmp_field = _metadata_arr[i_meta].split("[");
                                if (tmp_field.length > 1) {
                                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                                    var ws = tmp_field[0];
                                    if (ws == "m_1300ws") {
                                        var f_data = items_1300ws[fieldnya];
                                        itemObj[fieldnya] = "";
                                        if (f_data != undefined) {
                                            itemObj[fieldnya] = f_data == "null" ? "" : f_data;
                                        }
                                    }
                                }
                            }
                        } else if (items_1300ws == null) {
                            for (var i_meta in _metadata_arr) {
                                var tmp_field = _metadata_arr[i_meta].split("[");
                                if (tmp_field.length > 1) {
                                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                                    var ws = tmp_field[0];
                                    if (ws == "m_1300ws") {
                                        itemObj[fieldnya] = "";
                                    }
                                }
                            }
                        }
                        var items_1330ws = data[obj].m_1330ws;
                        if (items_1330ws != undefined || items_1330ws != null) {
                            for (var i_meta in _metadata_arr) {
                                var tmp_field = _metadata_arr[i_meta].split("[");
                                if (tmp_field.length > 1) {
                                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                                    var ws = tmp_field[0];
                                    if (ws == "m_1330ws") {
                                        var f_data = items_1330ws[fieldnya];
                                        itemObj[fieldnya] = "";
                                        if (f_data != undefined) {
                                            itemObj[fieldnya] = f_data == "null" ? "" : f_data;
                                        }
                                    }
                                }
                            }
                        } else if (items_1330ws == null) {
                            for (var i_meta in _metadata_arr) {
                                var tmp_field = _metadata_arr[i_meta].split("[");
                                if (tmp_field.length > 1) {
                                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                                    var ws = tmp_field[0];
                                    if (ws == "m_1330ws") {
                                        itemObj[fieldnya] = "";
                                    }
                                }
                            }
                        }
                        var items_1400ws = data[obj].m_1400ws;
                        if (items_1400ws != undefined || items_1400ws != null) {
                            for (var i_meta in _metadata_arr) {
                                var tmp_field = _metadata_arr[i_meta].split("[");
                                if (tmp_field.length > 1) {
                                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                                    var ws = tmp_field[0];
                                    if (ws == "m_1400ws") {
                                        var f_data = items_1400ws[fieldnya];
                                        itemObj[fieldnya] = "";
                                        if (f_data != undefined) {
                                            itemObj[fieldnya] = f_data == "null" ? "" : f_data;
                                        }
                                    }
                                }
                            }
                        } else if (items_1400ws == null) {
                            for (var i_meta in _metadata_arr) {
                                var tmp_field = _metadata_arr[i_meta].split("[");
                                if (tmp_field.length > 1) {
                                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                                    var ws = tmp_field[0];
                                    if (ws == "m_1400ws") {
                                        itemObj[fieldnya] = "";
                                    }
                                }
                            }
                        }
                        var items_1700ws = data[obj].m_1700ws;
                        if (items_1700ws != undefined || items_1700ws != null) {
                            for (var i_meta in _metadata_arr) {
                                var tmp_field = _metadata_arr[i_meta].split("[");
                                if (tmp_field.length > 1) {
                                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                                    var ws = tmp_field[0];
                                    if (ws == "m_1700ws") {
                                        var f_data = items_1700ws[fieldnya];
                                        itemObj[fieldnya] = "";
                                        if (f_data != undefined) {
                                            itemObj[fieldnya] = f_data == "null" ? "" : f_data;
                                        }
                                    }
                                }
                            }
                        } else if (items_1700ws == null) {
                            for (var i_meta in _metadata_arr) {
                                var tmp_field = _metadata_arr[i_meta].split("[");
                                if (tmp_field.length > 1) {
                                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                                    var ws = tmp_field[0];
                                    if (ws == "m_1700ws") {
                                        itemObj[fieldnya] = "";
                                    }
                                }
                            }
                        }
                        var items_1730ws = data[obj].m_1730ws;
                        if (items_1730ws != undefined || items_1730ws != null) {
                            for (var i_meta in _metadata_arr) {
                                var tmp_field = _metadata_arr[i_meta].split("[");
                                if (tmp_field.length > 1) {
                                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                                    var ws = tmp_field[0];
                                    if (ws == "m_1730ws") {
                                        var f_data = items_1730ws[fieldnya];
                                        itemObj[fieldnya] = "";
                                        if (f_data != undefined) {
                                            itemObj[fieldnya] = f_data == "null" ? "" : f_data;
                                        }
                                    }
                                }
                            }
                        } else if (items_1730ws == null) {
                            for (var i_meta in _metadata_arr) {
                                var tmp_field = _metadata_arr[i_meta].split("[");
                                if (tmp_field.length > 1) {
                                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                                    var ws = tmp_field[0];
                                    if (ws == "m_1730ws") {
                                        itemObj[fieldnya] = "";
                                    }
                                }
                            }
                        }
                        var items_1800ws = data[obj].m_1800ws;
                        if (items_1800ws != undefined || items_1800ws != null) {
                            for (var i_meta in _metadata_arr) {
                                var tmp_field = _metadata_arr[i_meta].split("[");
                                if (tmp_field.length > 1) {
                                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                                    var ws = tmp_field[0];
                                    if (ws == "m_1800ws") {
                                        var f_data = items_1800ws[fieldnya];
                                        itemObj[fieldnya] = "";
                                        if (f_data != undefined) {
                                            itemObj[fieldnya] = f_data == "null" ? "" : f_data;
                                        }
                                    }
                                }
                            }
                        } else if (items_1800ws == null) {
                            for (var i_meta in _metadata_arr) {
                                var tmp_field = _metadata_arr[i_meta].split("[");
                                if (tmp_field.length > 1) {
                                    var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                                    var ws = tmp_field[0];
                                    if (ws == "m_1800ws") {
                                        itemObj[fieldnya] = "";
                                    }
                                }
                            }
                        }

                        if (currentFrom == "OpPenguapan") {
                            // _metadata = _metadata + ",qc_flag_op_penguapan,qc_histories_op_penguapan";
                            var flag = data[obj].qc_flag_op_penguapan;
                            var histories = data[obj].qc_histories_op_penguapan;
                            itemObj["qc_flag"] = flag;
                            itemObj["qc_parameter"] = "";
                            if (flag == 1 || flag == 2) {
                                let JsonQc = JSON.parse(histories);
                                let Qc_His = JsonQc.after;
                                var Log_QC_His = "";
                                for (var i_qc in Qc_His) {
                                    Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                                }
                                let len = Log_QC_His.length;
                                let log = Log_QC_His;
                                if (len > 0) {
                                    log = Log_QC_His.substring(0, len - 1);
                                }

                                itemObj["qc_parameter"] = log;
                                // itemObj["qc_parameter"] = histories
                            }
                        }
                        if (currentFrom == "PichePenguapan") {
                            // _metadata = _metadata + ",qc_flag_piche_penguapan,qc_histories_piche_penguapan";
                            var flag = data[obj].qc_flag_piche_penguapan;
                            var histories = data[obj].qc_histories_piche_penguapan;
                            itemObj["qc_flag"] = flag;
                            if (flag == 1 || flag == 2) {
                                let JsonQc = JSON.parse(histories);
                                let Qc_His = JsonQc.after;
                                var Log_QC_His = "";
                                for (var i_qc in Qc_His) {
                                    Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                                }
                                let len = Log_QC_His.length;
                                let log = Log_QC_His;
                                if (len > 0) {
                                    log = Log_QC_His.substring(0, len - 1);
                                }

                                itemObj["qc_parameter"] = log;
                                // itemObj["qc_parameter"] = histories
                            }
                        }
                        if (currentFrom == "Fklim") {
                            var flag = data[obj].qc_flag_fklim;
                            var histories = data[obj].qc_histories_fklim;
                            itemObj["qc_flag"] = flag;
                            itemObj["qc_parameter"] = "";
                            if (flag == 1 || flag == 2) {
                                let JsonQc = JSON.parse(histories);
                                let Qc_His = JsonQc.after;
                                var Log_QC_His = "";
                                for (var i_qc in Qc_His) {
                                    Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                                }
                                let len = Log_QC_His.length;
                                let log = Log_QC_His;
                                if (len > 0) {
                                    log = Log_QC_His.substring(0, len - 1);
                                }

                                itemObj["qc_parameter"] = log;
                                // itemObj["qc_parameter"] = histories
                            }
                        }
                        if (currentFrom == "Agm1a") {
                            var flag = data[obj].qc_flag_agm1a;
                            var histories = data[obj].qc_histories_agm1a;
                            itemObj["qc_flag"] = flag;
                            itemObj["qc_parameter"] = "";
                            if (flag == 1 || flag == 2) {
                                let JsonQc = JSON.parse(histories);
                                let Qc_His = JsonQc.after;
                                var Log_QC_His = "";
                                for (var i_qc in Qc_His) {
                                    Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                                }
                                let len = Log_QC_His.length;
                                let log = Log_QC_His;
                                if (len > 0) {
                                    log = Log_QC_His.substring(0, len - 1);
                                }

                                itemObj["qc_parameter"] = log;
                                // itemObj["qc_parameter"] = histories
                            }
                            // console.log("Qc terpanggill");
                        }
                        if (currentFrom == "Agm1b") {
                            var flag = data[obj].qc_flag_agm1b;
                            var histories = data[obj].qc_histories_agm1b;
                            itemObj["qc_flag"] = flag;
                            itemObj["qc_parameter"] = "";
                            if (flag == 1 || flag == 2) {
                                let JsonQc = JSON.parse(histories);
                                let Qc_His = JsonQc.after;
                                var Log_QC_His = "";
                                for (var i_qc in Qc_His) {
                                    Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                                }
                                let len = Log_QC_His.length;
                                let log = Log_QC_His;
                                if (len > 0) {
                                    log = Log_QC_His.substring(0, len - 1);
                                }

                                itemObj["qc_parameter"] = log;
                                // itemObj["qc_parameter"] = histories
                            }
                        }
                        if (currentFrom == "SuhuTanah") {
                            var flag = data[obj].qc_flag_suhu_tanah;
                            var histories = data[obj].qc_histories_suhu_tanah;
                            itemObj["qc_flag"] = flag;
                            itemObj["qc_parameter"] = "";
                            if (flag == 1 || flag == 2) {
                                let JsonQc = JSON.parse(histories);
                                let Qc_His = JsonQc.after;
                                var Log_QC_His = "";
                                for (var i_qc in Qc_His) {
                                    Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                                }
                                let len = Log_QC_His.length;
                                let log = Log_QC_His;
                                if (len > 0) {
                                    log = Log_QC_His.substring(0, len - 1);
                                }

                                itemObj["qc_parameter"] = log;
                                // itemObj["qc_parameter"] = histories
                            }
                        }
                        if (currentFrom == "PsycrometerAssman") {
                            var flag = data[obj].qc_flag_psychrometer_assman;
                            var histories = data[obj].qc_histories_psychrometer_assman;
                            itemObj["qc_flag"] = flag;
                            itemObj["qc_parameter"] = "";
                            if (flag == 1 || flag == 2) {
                                let JsonQc = JSON.parse(histories);
                                let Qc_His = JsonQc.after;
                                var Log_QC_His = "";
                                for (var i_qc in Qc_His) {
                                    Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                                }
                                let len = Log_QC_His.length;
                                let log = Log_QC_His;
                                if (len > 0) {
                                    log = Log_QC_His.substring(0, len - 1);
                                }

                                itemObj["qc_parameter"] = log;
                                // itemObj["qc_parameter"] = histories
                            }
                        }
                        if (currentFrom == "Gunbellani") {
                            var flag = data[obj].qc_flag_gunbellani;
                            var histories = data[obj].qc_histories_gunbellani;
                            itemObj["qc_flag"] = flag;
                            itemObj["qc_parameter"] = "";
                            if (flag == 1 || flag == 2) {
                                let JsonQc = JSON.parse(histories);
                                let Qc_His = JsonQc.after;
                                var Log_QC_His = "";
                                for (var i_qc in Qc_His) {
                                    Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                                }
                                let len = Log_QC_His.length;
                                let log = Log_QC_His;
                                if (len > 0) {
                                    log = Log_QC_His.substring(0, len - 1);
                                }

                                itemObj["qc_parameter"] = log;
                                // itemObj["qc_parameter"] = histories
                            }
                        }
                        if (currentFrom == "IklimMikro") {
                            var flag = data[obj].qc_flag_iklim_mikro;
                            var histories = data[obj].qc_histories_iklim_mikro;
                            itemObj["qc_flag"] = flag;
                            itemObj["qc_parameter"] = "";
                            if (flag == 1 || flag == 2) {
                                let JsonQc = JSON.parse(histories);
                                let Qc_His = JsonQc.after;
                                var Log_QC_His = "";
                                for (var i_qc in Qc_His) {
                                    Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                                }
                                let len = Log_QC_His.length;
                                let log = Log_QC_His;
                                if (len > 0) {
                                    log = Log_QC_His.substring(0, len - 1);
                                }

                                itemObj["qc_parameter"] = log;
                                // itemObj["qc_parameter"] = histories
                            }
                        }
                        // console.log("itemObj : ", itemObj);
                        tempData.push(itemObj);
                    }
                    tempData.sort(function (a, b) {
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        return new Date(a.data_timestamp) - new Date(b.data_timestamp);
                    });
                    var x_result = [];
                    // itemObj["data_timestamp"] = data[obj].data_timestamp.substr(0,10);
                    //   itemObj["station_name"] = data[obj].station_name;
                    //   itemObj["current_latitude"] = data[obj].current_latitude;
                    //   itemObj["current_longitude"] = data[obj].current_longitude;
                    // console.log("temp data : ",tempData)
                    for (var y in tempData) {
                        var x_data = [
                            {
                                attr: "station_id",
                                index: 0,
                                value: tempData[y].station_id,
                            },
                            {
                                attr: "station_name",
                                index: 1,
                                value: tempData[y].station_name,
                            },
                            {
                                attr: "current_latitude",
                                index: 2,
                                value: tempData[y].current_latitude,
                            },
                            {
                                attr: "current_longitude",
                                index: 3,
                                value: tempData[y].current_longitude,
                            },
                            {
                                attr: "data_timestamp",
                                index: 4,
                                value: tempData[y].data_timestamp,
                            },
                        ];
                        var x_index = 5;
                        for (var x in _metadata_arr) {
                            if (_metadata_arr[x] == "observer_id" || _metadata_arr[x] == "observer_name") {
                                continue;
                            }
                            var tmp_field = _metadata_arr[x].split("[");

                            var x_field = _metadata_arr[x];
                            var x_local = {};
                            if (tmp_field.length > 1) {
                                var fieldnya = tmp_field[1].substr(0, tmp_field[1].length - 1);
                                x_field = fieldnya;
                            }
                            var attr = x_field;
                            // if (attr == "ws_avg_1b_0c5_1") {
                            //   attr = "ws_avg_1b_0c5_0730";
                            // }
                            // if (attr == "ws_avg_1b_0c5_2") {
                            //   attr = "ws_avg_1b_0c5_1330";
                            // }
                            // if (attr == "ws_avg_1b_0c5_3") {
                            //   attr = "ws_avg_1b_0c5_1730";
                            // }
                            // console.log("param asli : ", x_field, _metadata_arr, this.list_allias_parameters);
                            if (this.list_allias_parameters[x_field] != undefined) {
                                var allias = this.list_allias_parameters[x_field].alias_parameter;
                                // attr = allias.replace(".", "");
                                attr = allias;
                                // console.log("parameter = "+x_field+" , allias = "+allias);
                            }
                            x_local = {
                                attr: attr,
                                index: x_index,
                                value: tempData[y][x_field],
                            };
                            // console.log("tempData[y] : ",tempData[y])
                            // console.log("x_field : ",x_field)
                            // console.log("row : ", x_local, currentFrom);
                            if (currentFrom == "Fklim" && x_local.attr == "t_min_120cm_1400") {
                                x_local.attr = "t_min_120cm_1300";
                            }
                            // console.log("row : ", x_local, currentFrom);
                            x_data.push(x_local);
                            // x_data[x_field] = tempData[y][x_field];
                            x_index++;
                        }
                        if (tempData[y].observer_id != undefined) {
                            var obs_id = {
                                attr: "observer_id",
                                index: x_index,
                                value: tempData[y].observer_id,
                            };
                            x_data.push(obs_id);
                            x_index++;
                        }
                        if (tempData[y].observer_name != undefined) {
                            var obs_name = {
                                attr: "observer_name",
                                index: x_index,
                                value: tempData[y].observer_name,
                            };
                            x_data.push(obs_name);
                            x_index++;
                        }

                        if (
                            currentFrom == "Fklim" ||
                            currentFrom == "PichePenguapan" ||
                            currentFrom == "OpPenguapan" ||
                            currentFrom == "Agm1a" ||
                            currentFrom == "Agm1b" ||
                            currentFrom == "SuhuTanah" ||
                            currentFrom == "PsycrometerAssman" ||
                            currentFrom == "Gunbellani" ||
                            currentFrom == "IklimMikro"
                        ) {
                            var qc_flag = {
                                attr: "qc_flag",
                                index: x_index + 1,
                                value: tempData[y].qc_flag,
                            };
                            x_data.push(qc_flag);
                            var qc_histories = {
                                attr: "qc_parameter",
                                index: x_index + 1,
                                value: tempData[y].qc_parameter,
                            };
                            x_data.push(qc_histories);
                        }
                        x_result.push(x_data);
                    }
                    // console.log("x_result : ", x_result);
                    var m_result = [];
                    for (var m in x_result) {
                        var m_row = x_result[m];
                        var m_obj = {};
                        for (var f in m_row) {
                            var f_data = m_row[f];
                            // console.log("data F :",f);
                            // console.log("data F :",f_data);
                            m_obj[f_data.attr] = f_data.value == 9999 ? 9999 : f_data.value;
                        }
                        m_result.push(m_obj);
                    }
                    // console.log("result : ", m_result);
                    this.items = m_result;
                    // this.items = tempData;
                    this.STORE_EXPORT_PIAS = m_result;
                    this.totalRows = this.items.length;
                    this.showLoading = false;
                } catch (e) {
                    this.showLoading = false;
                    console.log(e);
                }
            } else if (currentFrom == "Fenologi") {
                try {
                    const { data } = await outputexport.getRawData(currentFrom, date_gte, date_lte, _statiun, _metadata, reg_selected, prov_selected, kab_selected);
                    var tempData = [];
                    var tempFeno = [];
                    for (var obj in data) {
                        var itemObj = {};
                        var itemFeno = {};
                        itemObj["alias_station_id"] = data[obj].alias_station_id;
                        itemObj["station_name"] = data[obj].station_name;
                        itemObj["current_latitude"] = data[obj].current_latitude;
                        itemObj["current_longitude"] = data[obj].current_longitude;
                        itemObj["data_timestamp"] = data[obj].data_timestamp.substr(0, 10);
                        // itemObj["id"] = data[obj].id;

                        var fenoA = data[obj].feno_golA;
                        if (fenoA != undefined) {
                            // itemFeno = {};
                            for (var objF in fenoA) {
                                itemFeno = {};
                                itemFeno["station_id"] = itemObj["alias_station_id"];
                                itemFeno["station_name"] = itemObj["station_name"];
                                itemFeno["data_timestamp"] = itemObj["data_timestamp"];
                                itemFeno["Golongan_Tanaman"] = "A";
                                if (_metadata.indexOf("feno_golA[tanaman_golA]") != "-1") {
                                    if (fenoA[objF].tanaman_golA != undefined) {
                                        if (itemObj["tanaman_golA"] == undefined) {
                                            itemObj["tanaman_golA"] = "";
                                            itemObj["tanaman_golA"] = fenoA[objF].tanaman_golA;
                                        } else {
                                            var temp = itemObj["tanaman_golA"];
                                            itemObj["tanaman_golA"] = temp + "," + fenoA[objF].tanaman_golA;
                                        }
                                        itemFeno["Jenis_Tanaman"] = fenoA[objF].tanaman_golA;
                                    } else {
                                        itemObj["tanaman_golA"] = "";
                                        itemFeno["Jenis_Tanaman"] = "";
                                    }
                                }
                                if (_metadata.indexOf("feno_golA[peramatan_golA]") != "-1") {
                                    if (fenoA[objF].peramatan_golA != undefined) {
                                        if (itemObj["peramatan_golA"] == undefined) {
                                            itemObj["peramatan_golA"] = "";
                                            itemObj["peramatan_golA"] = fenoA[objF].peramatan_golA;
                                        } else {
                                            var temp = itemObj["peramatan_golA"];
                                            itemObj["peramatan_golA"] = temp + "," + fenoA[objF].peramatan_golA;
                                        }
                                        itemFeno["Peramatan_Fenologi"] = fenoA[objF].peramatan_golA;
                                    } else {
                                        itemObj["peramatan_golA"] = "";
                                        itemFeno["Peramatan_Fenologi"] = "";
                                    }
                                }

                                if (_metadata.indexOf("feno_golA[fenomena_golA]") != "-1") {
                                    if (fenoA[objF].fenomena_golA != undefined) {
                                        if (itemObj["fenomena_golA"] == undefined) {
                                            itemObj["fenomena_golA"] = "";
                                            itemObj["fenomena_golA"] = fenoA[objF].fenomena_golA;
                                        } else {
                                            var temp = itemObj["fenomena_golA"];
                                            itemObj["fenomena_golA"] = temp + "," + fenoA[objF].fenomena_golA;
                                        }
                                        itemFeno["Fenomena"] = fenoA[objF].fenomena_golA;
                                    } else {
                                        itemObj["fenomena_golA"] = "";
                                        itemFeno["Peramatan_Fenologi"] = "";
                                    }
                                }
                                if (_metadata.indexOf("feno_golA[ket_feno_golA]") != "-1") {
                                    if (fenoA[objF].ket_feno_golA != undefined) {
                                        if (itemObj["ket_feno_golA"] == undefined) {
                                            itemObj["ket_feno_golA"] = "";
                                            itemObj["ket_feno_golA"] = fenoA[objF].ket_feno_golA;
                                        } else {
                                            var temp = itemObj["ket_feno_golA"];
                                            itemObj["ket_feno_golA"] = temp + "," + fenoA[objF].ket_feno_golA;
                                        }
                                        itemFeno["Keterangan"] = fenoA[objF].ket_feno_golA;
                                    } else {
                                        itemObj["ket_feno_golA"] = "";
                                        itemFeno["Keterangan"] = "";
                                    }
                                }
                                tempFeno.push(itemFeno);
                            }
                        }
                        var fenoB = data[obj].feno_golB;
                        if (fenoB != undefined) {
                            // itemFeno = {};
                            for (var objF in fenoB) {
                                itemFeno = {};
                                itemFeno["station_id"] = itemObj["alias_station_id"];
                                itemFeno["station_name"] = itemObj["station_name"];
                                itemFeno["data_timestamp"] = itemObj["data_timestamp"];
                                itemFeno["Golongan_Tanaman"] = "B";
                                if (_metadata.indexOf("feno_golB[tanaman_golB]") != "-1") {
                                    if (fenoB[objF].tanaman_golB != undefined) {
                                        if (itemObj["tanaman_golB"] == undefined) {
                                            itemObj["tanaman_golB"] = "";
                                            itemObj["tanaman_golB"] = fenoB[objF].tanaman_golB;
                                        } else {
                                            var temp = itemObj["tanaman_golB"];
                                            itemObj["tanaman_golB"] = temp + "," + fenoB[objF].tanaman_golB;
                                        }
                                        itemFeno["Jenis_Tanaman"] = fenoB[objF].tanaman_golB;
                                    } else {
                                        itemObj["tanaman_golB"] = "";
                                        itemFeno["Jenis_Tanaman"] = "";
                                    }
                                }
                                if (_metadata.indexOf("feno_golB[peramatan_golB]") != "-1") {
                                    if (fenoB[objF].peramatan_golB != undefined) {
                                        if (itemObj["peramatan_golB"] == undefined) {
                                            itemObj["peramatan_golB"] = "";
                                            itemObj["peramatan_golB"] = fenoB[objF].peramatan_golB;
                                        } else {
                                            var temp = itemObj["peramatan_golB"];
                                            itemObj["peramatan_golB"] = temp + "," + fenoB[objF].peramatan_golB;
                                        }
                                        itemFeno["Peramatan_Fenologi"] = fenoB[objF].peramatan_golB;
                                    } else {
                                        itemObj["peramatan_golB"] = "";
                                        itemFeno["Peramatan_Fenologi"] = "";
                                    }
                                }

                                if (_metadata.indexOf("feno_golB[fenomena_golB]") != "-1") {
                                    if (fenoB[objF].fenomena_golB != undefined) {
                                        if (itemObj["fenomena_golB"] == undefined) {
                                            itemObj["fenomena_golB"] = "";
                                            itemObj["fenomena_golB"] = fenoB[objF].fenomena_golB;
                                        } else {
                                            var temp = itemObj["fenomena_golB"];
                                            itemObj["fenomena_golB"] = temp + "," + fenoB[objF].fenomena_golB;
                                        }
                                        itemFeno["Fenomena"] = fenoB[objF].fenomena_golB;
                                    } else {
                                        itemObj["fenomena_golB"] = "";
                                        itemFeno["Fenomena"] = "";
                                    }
                                }
                                if (_metadata.indexOf("feno_golB[ket_feno_golB]") != "-1") {
                                    if (fenoB[objF].ket_feno_golB != undefined) {
                                        if (itemObj["ket_feno_golB"] == undefined) {
                                            itemObj["ket_feno_golB"] = "";
                                            itemObj["ket_feno_golB"] = fenoB[objF].ket_feno_golB;
                                        } else {
                                            var temp = itemObj["ket_feno_golB"];
                                            itemObj["ket_feno_golB"] = temp + "," + fenoB[objF].ket_feno_golB;
                                        }
                                        itemFeno["Keterangan"] = fenoB[objF].ket_feno_golB;
                                    } else {
                                        itemObj["ket_feno_golB"] = "";
                                        itemFeno["Keterangan"] = "";
                                    }
                                }
                                tempFeno.push(itemFeno);
                            }
                        }
                        var fenoC = data[obj].feno_golC;
                        if (fenoC != undefined) {
                            for (var objF in fenoC) {
                                itemFeno = {};
                                itemFeno["station_id"] = itemObj["alias_station_id"];
                                itemFeno["station_name"] = itemObj["station_name"];
                                itemFeno["data_timestamp"] = itemObj["data_timestamp"];
                                itemFeno["Golongan_Tanaman"] = "C";
                                if (_metadata.indexOf("feno_golC[tanaman_golC]") != "-1") {
                                    if (fenoC[objF].tanaman_golC != undefined) {
                                        if (itemObj["tanaman_golC"] == undefined) {
                                            itemObj["tanaman_golC"] = "";
                                            itemObj["tanaman_golC"] = fenoC[objF].tanaman_golC;
                                        } else {
                                            var temp = itemObj["tanaman_golC"];
                                            itemObj["tanaman_golC"] = temp + "," + fenoC[objF].tanaman_golC;
                                        }
                                        itemFeno["Jenis_Tanaman"] = fenoC[objF].tanaman_golC;
                                    } else {
                                        itemObj["tanaman_golC"] = "";
                                        itemFeno["Jenis_Tanaman"] = "";
                                    }
                                }
                                if (_metadata.indexOf("feno_golC[peramatan_golC]") != "-1") {
                                    if (fenoC[objF].peramatan_golC != undefined) {
                                        if (itemObj["peramatan_golC"] == undefined) {
                                            itemObj["peramatan_golC"] = "";
                                            itemObj["peramatan_golC"] = fenoC[objF].peramatan_golC;
                                        } else {
                                            var temp = itemObj["peramatan_golC"];
                                            itemObj["peramatan_golC"] = temp + "," + fenoC[objF].peramatan_golC;
                                        }
                                        itemFeno["Peramatan_Fenologi"] = fenoC[objF].peramatan_golC;
                                    } else {
                                        itemObj["peramatan_golC"] = "";
                                        itemFeno["Peramatan_Fenologi"] = "";
                                    }
                                }

                                if (_metadata.indexOf("feno_golC[fenomena_golC]") != "-1") {
                                    if (fenoC[objF].fenomena_golC != undefined) {
                                        if (itemObj["fenomena_golC"] == undefined) {
                                            itemObj["fenomena_golC"] = "";
                                            itemObj["fenomena_golC"] = fenoC[objF].fenomena_golC;
                                        } else {
                                            var temp = itemObj["fenomena_golC"];
                                            itemObj["fenomena_golC"] = temp + "," + fenoC[objF].fenomena_golC;
                                        }
                                        itemFeno["Fenomena"] = fenoC[objF].fenomena_golC;
                                    } else {
                                        itemObj["fenomena_golC"] = "";
                                        itemFeno["Fenomena"] = "";
                                    }
                                }
                                if (_metadata.indexOf("feno_golC[ket_feno_golC]") != "-1") {
                                    if (fenoC[objF].ket_feno_golC != undefined) {
                                        if (itemObj["ket_feno_golC"] == undefined) {
                                            itemObj["ket_feno_golC"] = "";
                                            itemObj["ket_feno_golC"] = fenoC[objF].ket_feno_golC;
                                        } else {
                                            var temp = itemObj["ket_feno_golC"];
                                            itemObj["ket_feno_golC"] = temp + "," + fenoC[objF].ket_feno_golC;
                                        }
                                        itemFeno["Keterangan"] = fenoC[objF].ket_feno_golC;
                                    } else {
                                        itemObj["ket_feno_golC"] = "";
                                        itemFeno["Keterangan"] = "";
                                    }
                                }
                                tempFeno.push(itemFeno);
                            }
                        }
                        // console.log(itemObj)
                        tempData.push(itemObj);
                    }
                    // console.log(tempData)

                    this.items = tempFeno;
                    this.STORE_EXPORT_PIAS = tempFeno;
                    // this.items = tempData;
                    // this.STORE_EXPORT_PIAS = tempData;
                    this.totalRows = this.items.length;
                    this.showLoading = false;
                } catch (e) {
                    console.log(e);
                    this.showLoading = false;
                }
            } else if (currentFrom == "Sinoptik") {
                try {
                    _metadata = _metadata + ",qc_flag,qc_histories,qc_stepcheck_flag,qc_stepcheck_histories";
                    var meteo_lte = date_lte;
                    if (twoDate) {
                        meteo_lte = date_lte + "T23:59";
                    }

                    const { data } = await outputexport.getRawDataSinoptik(currentFrom, date_gte, meteo_lte, _statiun, _metadata, reg_selected, prov_selected, kab_selected);
                    var dataTemp = [];
                    for (var items in data) {
                        var dataRow = {};
                        // console.log("items : ",items);
                        //  console.log("data[items] : ",data[items]);
                        for (var item in data[items]) {
                            // console.log("item : ", item);
                            // console.log("data[items].item : ", data[items][item]);
                            if (item == "alias_station_id") {
                                continue;
                            } else if (item == "source_data") {
                                continue;
                            } else if (item == "station_id") {
                                dataRow["station_id"] = data[items]["alias_station_id"];
                            } else if (item == "data_timestamp") {
                                var data_timestamp = data[items][item].substr(0, 13);
                                dataRow["timestamp"] = data_timestamp.replace("T", " ");
                            }
                            // else if (item == "qc_flag" || item == "qc_stepcheck_flag") {
                            //   if (data[items].qc_flag == 1 || data[items].qc_flag == 2 || data[items].qc_flag == 1 || data[items].qc_flag == 2) {
                            // }
                            else if (item == "encoded_synop") {
                                dataRow["encoded_synop"] = data[items]["encoded_synop"].replace(/\n/g, " ");
                            } else if (item == "qc_flag" || item == "qc_stepcheck_flag") {
                                let rc = data[items]["qc_flag"];
                                let sc = data[items]["qc_stepcheck_flag"];
                                let flag = "";
                                if (rc == 1 || sc == 1) {
                                    flag = 1;
                                } else if (rc == 2 || sc == 2) {
                                    flag = 2;
                                } else {
                                    flag = 0;
                                }
                                dataRow["qc_flag"] = flag;
                            } else if (item == "qc_histories" || item == "qc_stepcheck_histories") {
                                if (data[items].qc_flag == 1 || data[items].qc_flag == 2) {
                                    let JsonQc = JSON.parse(data[items]["qc_histories"]);
                                    let Qc_His = JsonQc.after;
                                    var Log_QC_His = "";
                                    for (var i_qc in Qc_His) {
                                        Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                                    }
                                    let len = Log_QC_His.length;
                                    let log = Log_QC_His;
                                    if (len > 0) {
                                        log = Log_QC_His.substring(0, len - 1);
                                    }
                                    // let log = Log_QC_His.substring(0, len - 1);

                                    dataRow["qc_parameter"] = log;
                                } else {
                                    dataRow["qc_parameter"] = "";
                                }

                                if (data[items].qc_stepcheck_flag == 1 || data[items].qc_stepcheck_flag == 2) {
                                    let JsonQc = JSON.parse(data[items]["qc_stepcheck_histories"]);
                                    let Qc_His = JsonQc.after;
                                    var Log_QC_His = "";
                                    for (var i_qc in Qc_His) {
                                        Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                                    }
                                    let len = Log_QC_His.length;
                                    let log = Log_QC_His;
                                    if (len > 0) {
                                        log = Log_QC_His.substring(0, len - 1);
                                    }
                                    // let log = Log_QC_His.substring(0, len - 1);

                                    dataRow["qc_parameter"] = dataRow["qc_parameter"] ? dataRow["qc_parameter"] + log : log;
                                } else {
                                    dataRow["qc_parameter"] = dataRow["qc_parameter"] ? dataRow["qc_parameter"] : "";
                                }
                            }
                            // else if (item == "qc_stepcheck_flag") {
                            //   dataRow["qc_sc_flag"] = data[items]["qc_stepcheck_flag"];
                            // }
                            // else if (item == "qc_stepcheck_histories") {
                            //   if (data[items].qc_stepcheck_flag == 1 || data[items].qc_stepcheck_flag == 2) {
                            //     let JsonQc = JSON.parse(data[items][item]);
                            //     let Qc_His = JsonQc.after;
                            //     var Log_QC_His = "";
                            //     for (var i_qc in Qc_His) {
                            //       Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                            //     }
                            //     let len = Log_QC_His.length;
                            //     let log = Log_QC_His;
                            //     if (len > 0) {
                            //       log = Log_QC_His.substring(0, len - 1);
                            //     }
                            //     // let log = Log_QC_His.substring(0, len - 1);

                            //     dataRow["qc_sc_parameter"] = log;
                            //   } else {
                            //     dataRow["qc_sc_parameter"] = "";
                            //   }
                            // }
                            else {
                                if (this.list_allias_parameters[item] != undefined) {
                                    var allias = this.list_allias_parameters[item].alias_parameter;

                                    dataRow[allias] = data[items][item];
                                } else {
                                    dataRow[item] = data[items][item];
                                }
                            }
                        }
                        dataTemp.push(dataRow);
                    }
                    this.items = dataTemp;
                    this.STORE_EXPORT_PIAS = dataTemp;

                    this.totalRows = this.items.length;
                    this.showLoading = false;
                } catch (e) {
                    console.log(e);
                    this.showLoading = false;
                }
            } else if (currentFrom == "MetarSpeci") {
                var meteo_lte = date_lte;
                if (twoDate) {
                    meteo_lte = date_lte + "T23:59";
                }
                _metadata = _metadata + ",qc_flag,qc_histories";
                var cek_arr_meta = _metadata.split(",");
                try {
                    const { data } = await outputexport.getRawData(currentFrom, date_gte, meteo_lte, _statiun, _metadata, reg_selected, prov_selected, kab_selected);

                    var dataTemp = [];

                    for (var items in data) {
                        var dataRow = {};
                        // console.log("items : ",items);
                        //  console.log("data[items] : ",data[items]);

                        var findClm = {};
                        for (var item in data[items]) {
                            // console.log("item : ", item);
                            // console.log("data[items].item : ", data[items][item]);
                            var dataItem = data[items][item];

                            if (item == "alias_station_id") {
                                continue;
                            } else if (item == "source_data") {
                                continue;
                            } else if (item == "station_id") {
                                dataRow["station_id"] = data[items]["alias_station_id"];
                            } else if (item == "data_timestamp") {
                                var data_timestamp = dataItem.substr(0, 16);
                                dataRow["data_timestamp"] = data_timestamp.replace("T", " ");
                            } else if (item == "m_cloud") {
                                var m_cloud = data[items]["m_cloud"];
                                console.log("m_cloud  ", m_cloud);
                                for (var j = 1; j <= 6; j++) {
                                    if (m_cloud == null) {
                                        if (cek_arr_meta.indexOf("m_cloud[amount_nsnsns]") != "-1") {
                                            dataRow["m_cloud_amount_nsnsns_" + j] = "";
                                        }
                                        if (cek_arr_meta.indexOf("m_cloud[height_hshshs]") != "-1") {
                                            dataRow["m_cloud_height_hshshs_" + j] = "";
                                        }
                                        if (cek_arr_meta.indexOf("m_cloud[type]") != "-1") {
                                            dataRow["m_cloud_type_" + j] = "";
                                        }
                                    } else {
                                        if (m_cloud[j] == undefined) {
                                            if (cek_arr_meta.indexOf("m_cloud[amount_nsnsns]") != "-1") {
                                                dataRow["m_cloud_amount_nsnsns_" + j] = "";
                                            }
                                            if (cek_arr_meta.indexOf("m_cloud[height_hshshs]") != "-1") {
                                                dataRow["m_cloud_height_hshshs_" + j] = "";
                                            }
                                            if (cek_arr_meta.indexOf("m_cloud[type]") != "-1") {
                                                dataRow["m_cloud_type_" + j] = "";
                                            }
                                        } else {
                                            if (cek_arr_meta.indexOf("m_cloud[amount_nsnsns]") != "-1") {
                                                dataRow["m_cloud_amount_nsnsns_" + j] = m_cloud[j].amount_nsnsns;
                                            }
                                            if (cek_arr_meta.indexOf("m_cloud[height_hshshs]") != "-1") {
                                                dataRow["m_cloud_height_hshshs_" + j] = m_cloud[j].height_hshshs;
                                            }
                                            if (cek_arr_meta.indexOf("m_cloud[type]") != "-1") {
                                                dataRow["m_cloud_type_" + j] = m_cloud[j].type;
                                            }
                                        }
                                    }
                                }

                                // var keyHeader = {};
                                // for (var key in data[items][item]) {
                                //   for (var indexKey in data[items][item][key]) {
                                //     if (keyHeader[indexKey] == undefined) {
                                //       keyHeader[indexKey] = "";
                                //     }
                                //     keyHeader[indexKey] += keyHeader[indexKey] == "" ? data[items][item][key][indexKey] : "," + data[items][item][key][indexKey];
                                //   }
                                // }
                                // for (var headerIndex in keyHeader) {
                                //   dataRow[headerIndex] = keyHeader[headerIndex];
                                // }

                                // for(var obj in dataItem){
                                //   var dataObj = dataItem[obj];
                                //   if(dataObj["amount_nsnsns"] != undefined){
                                //     if(dataRow["amount_nsnsns"] == undefined){
                                //       dataRow["amount_nsnsns"] = dataObj["amount_nsnsns"]
                                //     }else{
                                //        dataRow["amount_nsnsns"] = dataRow["amount_nsnsns"]+", "+dataObj["amount_nsnsns"]
                                //     }
                                //   }
                            } else if (item == "m_runway") {
                                var m_runway = data[items]["m_runway"];
                                // console.log("m_runway  ",m_runway)
                                for (var j = 1; j <= 8; j++) {
                                    if (m_runway == null) {
                                        if (cek_arr_meta.indexOf("m_runway[state_ind_drdr]") != "-1") {
                                            dataRow["m_runway_state_ind_drdr_" + j] = "";
                                        }
                                        if (cek_arr_meta.indexOf("m_runway[depos_state_er]") != "-1") {
                                            dataRow["m_runway_depos_state_er_" + j] = "";
                                        }
                                        if (cek_arr_meta.indexOf("m_runway[depos_cr]") != "-1") {
                                            dataRow["m_runway_depos_cr_" + j] = "";
                                        }
                                        if (cek_arr_meta.indexOf("m_runway[depos_depth_erer]") != "-1") {
                                            dataRow["m_runway_depos_depth_erer_" + j] = "";
                                        }
                                        if (cek_arr_meta.indexOf("m_runway[depos_braking_brbr]") != "-1") {
                                            dataRow["m_runway_depos_braking_brbr_" + j] = "";
                                        }
                                    } else {
                                        if (m_runway[j] == undefined) {
                                            if (cek_arr_meta.indexOf("m_runway[state_ind_drdr]") != "-1") {
                                                dataRow["m_runway_state_ind_drdr_" + j] = "";
                                            }
                                            if (cek_arr_meta.indexOf("m_runway[depos_state_er]") != "-1") {
                                                dataRow["m_runway_depos_state_er_" + j] = "";
                                            }
                                            if (cek_arr_meta.indexOf("m_runway[depos_cr]") != "-1") {
                                                dataRow["m_runway_depos_cr_" + j] = "";
                                            }
                                            if (cek_arr_meta.indexOf("m_runway[depos_depth_erer]") != "-1") {
                                                dataRow["m_runway_depos_depth_erer_" + j] = "";
                                            }
                                            if (cek_arr_meta.indexOf("m_runway[depos_braking_brbr]") != "-1") {
                                                dataRow["m_runway_depos_braking_brbr_" + j] = "";
                                            }
                                        } else {
                                            if (cek_arr_meta.indexOf("m_runway[state_ind_drdr]") != "-1") {
                                                dataRow["m_runway_state_ind_drdr_" + j] = m_runway[j].state_ind_drdr;
                                            }
                                            if (cek_arr_meta.indexOf("m_runway[depos_state_er]") != "-1") {
                                                dataRow["m_runway_depos_state_er_" + j] = m_runway[j].depos_state_er;
                                            }
                                            if (cek_arr_meta.indexOf("m_runway[depos_cr]") != "-1") {
                                                dataRow["m_runway_depos_cr_" + j] = m_runway[j].depos_cr;
                                            }
                                            if (cek_arr_meta.indexOf("m_runway[depos_depth_erer]") != "-1") {
                                                dataRow["m_runway_depos_depth_erer_" + j] = m_runway[j].depos_depth_erer;
                                            }
                                            if (cek_arr_meta.indexOf("m_runway[depos_braking_brbr]") != "-1") {
                                                dataRow["m_runway_depos_braking_brbr_" + j] = m_runway[j].depos_braking_brbr;
                                            }
                                        }
                                    }
                                }
                                // var keyHeader = {};
                                // for (var key in data[items][item]) {
                                //   for (var indexKey in data[items][item][key]) {
                                //     if (keyHeader[indexKey] == undefined) {
                                //       keyHeader[indexKey] = "";
                                //     }
                                //     keyHeader[indexKey] += keyHeader[indexKey] == "" ? data[items][item][key][indexKey] : "," + data[items][item][key][indexKey];
                                //   }
                                // }
                                // for (var headerIndex in keyHeader) {
                                //   dataRow[headerIndex] = keyHeader[headerIndex];
                                // }
                            } else if (item == "m_rvr") {
                                var m_rvr = data[items]["m_rvr"];
                                // console.log("m_rvr  ",m_rvr)
                                for (var j = 1; j <= 8; j++) {
                                    if (m_rvr == null) {
                                        if (cek_arr_meta.indexOf("m_rvr[designator_drdr]") != "-1") {
                                            dataRow["m_rvr_designator_drdr_" + j] = "";
                                        }
                                        if (cek_arr_meta.indexOf("m_rvr[vrvrvrvr]") != "-1") {
                                            dataRow["m_rvr_vrvrvrvr_" + j] = "";
                                        }
                                        if (cek_arr_meta.indexOf("m_rvr[instrument_limit]") != "-1") {
                                            dataRow["m_rvr_instrument_limit_" + j] = "";
                                        }
                                        if (cek_arr_meta.indexOf("m_rvr[tendency_i]") != "-1") {
                                            dataRow["m_rvr_tendency_i_" + j] = "";
                                        }
                                    } else {
                                        if (m_rvr[j] == undefined) {
                                            if (cek_arr_meta.indexOf("m_rvr[designator_drdr]") != "-1") {
                                                dataRow["m_rvr_designator_drdr_" + j] = "";
                                            }
                                            if (cek_arr_meta.indexOf("m_rvr[vrvrvrvr]") != "-1") {
                                                dataRow["m_rvr_vrvrvrvr_" + j] = "";
                                            }
                                            if (cek_arr_meta.indexOf("m_rvr[instrument_limit]") != "-1") {
                                                dataRow["m_rvr_instrument_limit_" + j] = "";
                                            }
                                            if (cek_arr_meta.indexOf("m_rvr[tendency_i]") != "-1") {
                                                dataRow["m_rvr_tendency_i_" + j] = "";
                                            }
                                        } else {
                                            if (cek_arr_meta.indexOf("m_rvr[designator_drdr]") != "-1") {
                                                dataRow["m_rvr_designator_drdr_" + j] = m_rvr[j].designator_drdr;
                                            }
                                            if (cek_arr_meta.indexOf("m_rvr[vrvrvrvr]") != "-1") {
                                                dataRow["m_rvr_vrvrvrvr_" + j] = m_rvr[j].vrvrvrvr;
                                            }
                                            if (cek_arr_meta.indexOf("m_rvr[instrument_limit]") != "-1") {
                                                dataRow["m_rvr_instrument_limit_" + j] = m_rvr[j].instrument_limit;
                                            }
                                            if (cek_arr_meta.indexOf("m_rvr[tendency_i]") != "-1") {
                                                dataRow["m_rvr_tendency_i_" + j] = m_rvr[j].tendency_i;
                                            }
                                        }
                                    }
                                }
                                // var keyHeader = {};
                                // for (var key in data[items][item]) {
                                //   for (var indexKey in data[items][item][key]) {
                                //     if (keyHeader[indexKey] == undefined) {
                                //       keyHeader[indexKey] = "";
                                //     }
                                //     keyHeader[indexKey] += keyHeader[indexKey] == "" ? data[items][item][key][indexKey] : "," + data[items][item][key][indexKey];
                                //   }
                                // }
                                // for (var headerIndex in keyHeader) {
                                //   dataRow[headerIndex] = keyHeader[headerIndex];
                                // }
                            } else if (item == "m_wind_shear") {
                                var m_wind_shear = data[items]["m_wind_shear"];
                                for (var j = 1; j <= 8; j++) {
                                    if (m_wind_shear == null) {
                                        if (cek_arr_meta.indexOf("m_wind_shear[runway_ind_drdr]") != "-1") {
                                            dataRow["m_wind_shear_runway_ind_drdr_" + j] = "";
                                        }
                                    } else {
                                        if (m_wind_shear[j] == undefined) {
                                            if (cek_arr_meta.indexOf("m_wind_shear[runway_ind_drdr]") != "-1") {
                                                dataRow["m_wind_shear_runway_ind_drdr_" + j] = "";
                                            }
                                        } else {
                                            if (cek_arr_meta.indexOf("m_wind_shear[runway_ind_drdr]") != "-1") {
                                                dataRow["m_wind_shear_runway_ind_drdr_" + j] = m_wind_shear[j].runway_ind_drdr;
                                            }
                                        }
                                    }
                                }

                                // var keyHeader = {};
                                // for (var key in data[items][item]) {
                                //   for (var indexKey in data[items][item][key]) {
                                //     if (keyHeader[indexKey] == undefined) {
                                //       keyHeader[indexKey] = "";
                                //     }
                                //     keyHeader[indexKey] += keyHeader[indexKey] == "" ? data[items][item][key][indexKey] : "," + data[items][item][key][indexKey];
                                //   }
                                // }
                                // for (var headerIndex in keyHeader) {
                                //   dataRow[headerIndex] = keyHeader[headerIndex];
                                // }
                            } else if (item == "m_trend_cloud") {
                                var m_trend_cloud = data[items]["m_trend_cloud"];
                                // console.log("m_trend_cloud : ", m_trend_cloud);
                                for (var j = 1; j <= 4; j++) {
                                    if (m_trend_cloud == null) {
                                        if (cek_arr_meta.indexOf("m_trend_cloud[amount_nsnsns]") != "-1") {
                                            dataRow["m_trend_cloud_amount_nsnsns_" + j] = "";
                                        }
                                        if (cek_arr_meta.indexOf("m_trend_cloud[height_hshshs]") != "-1") {
                                            dataRow["m_trend_cloud_height_hshshs_" + j] = "";
                                        }
                                        if (cek_arr_meta.indexOf("m_trend_cloud[type]") != "-1") {
                                            dataRow["m_trend_cloud_type_" + j] = "";
                                        }
                                    } else {
                                        if (m_trend_cloud[j] == undefined) {
                                            if (cek_arr_meta.indexOf("m_trend_cloud[amount_nsnsns]") != "-1") {
                                                dataRow["m_trend_cloud_amount_nsnsns_" + j] = "";
                                            }
                                            if (cek_arr_meta.indexOf("m_trend_cloud[height_hshshs]") != "-1") {
                                                dataRow["m_trend_cloud_height_hshshs_" + j] = "";
                                            }
                                            if (cek_arr_meta.indexOf("m_trend_cloud[type]") != "-1") {
                                                dataRow["m_trend_cloud_type_" + j] = "";
                                            }
                                        } else {
                                            if (cek_arr_meta.indexOf("m_trend_cloud[amount_nsnsns]") != "-1") {
                                                dataRow["m_trend_cloud_amount_nsnsns_" + j] = m_trend_cloud[j].amount_nsnsns;
                                            }
                                            if (cek_arr_meta.indexOf("m_trend_cloud[height_hshshs]") != "-1") {
                                                dataRow["m_trend_cloud_height_hshshs_" + j] = m_trend_cloud[j].height_hshshs;
                                            }
                                            if (cek_arr_meta.indexOf("m_trend_cloud[type]") != "-1") {
                                                dataRow["m_trend_cloud_type_" + j] = m_trend_cloud[j].type;
                                            }
                                        }
                                    }
                                }
                                // var keyHeader = {};
                                // for (var key in data[items][item]) {
                                //   for (var indexKey in data[items][item][key]) {
                                //     if (keyHeader[indexKey] == undefined) {
                                //       keyHeader[indexKey] = "";
                                //     }
                                //     keyHeader[indexKey] += keyHeader[indexKey] == "" ? data[items][item][key][indexKey] : "," + data[items][item][key][indexKey];
                                //   }
                                // }
                                // for (var headerIndex in keyHeader) {
                                //   dataRow[headerIndex] = keyHeader[headerIndex];
                                // }
                            } else if (item == "qc_histories") {
                                if (data[items].qc_flag == 1 || data[items].qc_flag == 2) {
                                    let JsonQc = JSON.parse(data[items][item]);
                                    let Qc_His = JsonQc.after;
                                    var Log_QC_His = "";
                                    for (var i_qc in Qc_His) {
                                        Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                                    }
                                    let len = Log_QC_His.length;
                                    let log = Log_QC_His;
                                    if (len > 0) {
                                        log = Log_QC_His.substring(0, len - 1);
                                    }

                                    dataRow["qc_parameter"] = log;
                                } else {
                                    dataRow["qc_parameter"] = "";
                                }
                            } else {
                                if (item == "m_dew_point_temp_tdtd") {
                                    if (data[items][item] === "" || data[items][item] === null) {
                                        dataRow[item] = "";
                                    } else {
                                        dataRow[item] = parseFloat(data[items][item]).toFixed(1);
                                    }
                                } else if (item == "m_qnh_pressure_phphph") {
                                    if (data[items][item] === "" || data[items][item] === null) {
                                        dataRow[item] = "";
                                    } else {
                                        dataRow[item] = parseFloat(data[items][item]).toFixed(1);
                                    }
                                } else if (item == "m_sea_surface_temperature_tsts") {
                                    if (data[items][item] === "" || data[items][item] === null) {
                                        dataRow[item] = "";
                                    } else {
                                        dataRow[item] = parseFloat(data[items][item]).toFixed(1);
                                    }
                                } else if (item == "m_air_temperature_tt") {
                                    if (data[items][item] === "" || data[items][item] === null) {
                                        dataRow[item] = "";
                                    } else {
                                        dataRow[item] = parseFloat(data[items][item]).toFixed(1);
                                    }
                                } else {
                                    dataRow[item] = data[items][item];
                                }
                            }
                        }
                        dataTemp.push(dataRow);
                    }
                    this.items = dataTemp;
                    this.STORE_EXPORT_PIAS = dataTemp;

                    this.totalRows = this.items.length;
                    this.showLoading = false;
                } catch (e) {
                    console.log(e);
                    this.showLoading = false;
                }
            } else if (currentFrom == "Pibal") {
                this.csv_data_pibal = [];
                this.csv_raw_pibal = [];
                var meteo_lte = date_lte;
                if (twoDate) {
                    meteo_lte = date_lte + "T23:59";
                }

                try {
                    _metadata = _metadata + ",station_wmo_id";
                    const { data } = await outputexport.getRawData(currentFrom, date_gte, meteo_lte, _statiun, _metadata, reg_selected, prov_selected, kab_selected);

                    var dataTemp = [];

                    for (var items in data) {
                        var dataRow = {};
                        // console.log("items : ",items);
                        //  console.log("data[items] : ",data[items]);

                        var findClm = {};
                        for (var item in data[items]) {
                            // console.log("item : ", item);
                            // console.log("data[items].item : ", data[items][item]);
                            var dataItem = data[items][item];

                            if (item == "alias_station_id") {
                                continue;
                            } else if (item == "source_data") {
                                continue;
                            } else if (item == "station_id") {
                                dataRow["station_id"] = data[items]["alias_station_id"];
                            } else if (item == "data_timestamp") {
                                var data_timestamp = dataItem.substr(0, 16);
                                dataRow["data_timestamp"] = data_timestamp.replace("T", " ");
                            } else if (item == "hasil_lapisan_wd_ws_dddff") {
                                var keyHeader = {};
                                // console.log("data[items].item : ", data[items][item]);

                                for (var key in data[items][item]) {
                                    var data_pibal = {};
                                    data_pibal["station_id"] = data[items]["alias_station_id"];
                                    data_pibal["station_name"] = data[items]["station_name"];
                                    data_pibal["station_wmo_id"] = data[items]["station_wmo_id"];
                                    data_pibal["data_timestamp"] = data[items]["data_timestamp"];
                                    data_pibal["lapisan"] = data[items][item][key].lapisan;
                                    data_pibal["wd"] = data[items][item][key].wd;
                                    data_pibal["ws"] = data[items][item][key].ws;
                                    data_pibal["dddff"] = data[items][item][key].dddff;
                                    // data
                                    for (var indexKey in data[items][item][key]) {
                                        if (keyHeader[indexKey] == undefined) {
                                            keyHeader[indexKey] = "";
                                        }
                                        keyHeader[indexKey] += keyHeader[indexKey] == "" ? data[items][item][key][indexKey] : "," + data[items][item][key][indexKey];
                                        // data_pibal[indexKey] = data[items][item][key][indexKey];
                                    }
                                    // console.log(data_pibal["lapisan"]);
                                    // console.log(data[items][item][key].lapisan)
                                    // console.log("data lapisan",data[items][item][key].lapisan)
                                    // console.log("data ws wd",data[items][item][key])
                                    // console.log("data pibal ",data_pibal)
                                    if (data_pibal["lapisan"] != -1 || data_pibal["lapisan"] != "-1") {
                                        this.csv_data_pibal.push(data_pibal);
                                    }
                                }
                                for (var headerIndex in keyHeader) {
                                    dataRow[headerIndex] = keyHeader[headerIndex];
                                }
                                // console.log("data pibal : ",this.csv_data_pibal)
                            } else if (item == "pembacaan_azimuth_elevasi") {
                                var keyHeader = {};
                                var pembacaan = 1;
                                for (var key in data[items][item]) {
                                    var raw_pibal = {};
                                    raw_pibal["station_id"] = data[items]["alias_station_id"];
                                    raw_pibal["station_name"] = data[items]["station_name"];
                                    raw_pibal["station_wmo_id"] = data[items]["station_wmo_id"];
                                    raw_pibal["data_timestamp"] = data[items]["data_timestamp"];
                                    raw_pibal["pembacaan"] = pembacaan;
                                    for (var indexKey in data[items][item][key]) {
                                        raw_pibal[indexKey] = data[items][item][key][indexKey];
                                        if (keyHeader[indexKey] == undefined) {
                                            keyHeader[indexKey] = "";
                                        }
                                        keyHeader[indexKey] += keyHeader[indexKey] == "" ? data[items][item][key][indexKey] : "," + data[items][item][key][indexKey];
                                    }

                                    if (data[items]["wind_dir_surface"] != undefined) {
                                        if (pembacaan == 1) {
                                            raw_pibal["wind_dir_surface"] = data[items]["wind_dir_surface"];
                                        }
                                    }
                                    if (data[items]["wind_speed_surface"] != undefined) {
                                        if (pembacaan == 1) {
                                            raw_pibal["wind_speed_surface"] = data[items]["wind_speed_surface"];
                                        }
                                    }
                                    pembacaan++;

                                    this.csv_raw_pibal.push(raw_pibal);
                                }
                                for (var headerIndex in keyHeader) {
                                    dataRow[headerIndex] = keyHeader[headerIndex];
                                }
                            } else {
                                dataRow[item] = data[items][item];
                            }
                        }
                        dataTemp.push(dataRow);
                    }
                    this.items = dataTemp;
                    this.STORE_EXPORT_PIAS = dataTemp;

                    this.totalRows = this.items.length;
                    this.showLoading = false;
                    if (this.items.length > 0) {
                        this.pibaldataform = true;
                    } else {
                        this.pibaldataform = false;
                    }
                } catch (e) {
                    console.log(e);
                    this.showLoading = false;
                }
            } else if (currentFrom == "FormPerawanan") {
                try {
                    var date_lte_awan = date_lte;
                    if (twoDate) {
                        date_lte_awan = date_lte + "T23:59";
                    }
                    const { data } = await outputexport.getRawData(currentFrom, date_gte, date_lte_awan, _statiun, _metadata, reg_selected, prov_selected, kab_selected);
                    // console.log("PIAS");
                    var dataTemp = [];
                    for (var items in data) {
                        var dataRow = {};
                        // console.log("items : ",items);
                        //  console.log("data[items] : ",data[items]);
                        for (var item in data[items]) {
                            // console.log("item : ", item);
                            // console.log("data[items].item : ", data[items][item]);
                            if (item == "alias_station_id") {
                                continue;
                            } else if (item == "source_data") {
                                continue;
                            } else if (item == "station_id") {
                                dataRow["station_id"] = data[items]["alias_station_id"];
                            } else if (item == "data_timestamp") {
                                var data_timestamp = data[items][item];
                                dataRow["data_timestamp"] = data_timestamp.substr(0, 10);
                                // } else if (item == "obsawan_total_24") {
                                //   dataRow["total_pengamatan_24"] = data[items][item];
                            } else {
                                // console.log("parameter : ", item);
                                if (this.list_allias_parameters[item] != undefined) {
                                    var allias = this.list_allias_parameters[item].alias_parameter;
                                    // console.log("parameter : "+item+" allias : "+allias);
                                    dataRow[allias] = data[items][item];
                                } else {
                                    dataRow[item] = data[items][item];
                                }
                                // dataRow[item] = data[items][item];
                            }
                        }
                        dataTemp.push(dataRow);
                    }
                    // console.log("dataTemp : ", dataTemp);
                    this.STORE_EXPORT_PIAS = dataTemp;
                    this.items = dataTemp;
                    this.totalRows = this.items.length;
                    this.showLoading = false;
                } catch (e) {
                    console.log(e);
                    this.showLoading = false;
                }
            } else if (currentFrom == "Kah" || currentFrom == "Aerosol" || currentFrom == "Spm" || currentFrom == "So2no2") {
                var _metadata_KU = "";
                _metadata_KU = "station_id,station_name,current_latitude,current_longitude,tgl_pasang,tgl_angkat,alias_station_id,source_data,tahun_form,periode_form," + _metadata_arr.toString() + ",qc_flag,qc_histories";
                var FORM_KU = currentFrom;
                if (currentFrom == "Aerosol") {
                    FORM_KU = "Aerosol";
                } else if (currentFrom == "Kah") {
                    FORM_KU = "KAH";
                } else if (currentFrom == "So2no2") {
                    FORM_KU = "SO2NO2";
                } else if (currentFrom == "Spm") {
                    FORM_KU = "SPM";
                }
                // if(currentFrom == "Kah" || currentFrom == "Spm" || currentFrom == "So2no2"){
                //   this.$swal({
                //     title: "ERROR",
                //     text: "Api Belum Terintegrasi ",
                //     icon: "error",
                //     customClass: {
                //       confirmButton: "btn btn-danger",
                //     },
                //     buttonsStyling: false,
                //   });
                //   this.ready_export = false;
                //   this.showLoading = false;
                //   return;
                // }
                try {
                    const { data } = await outputexport.getRawDataKU(FORM_KU, date_gte, date_lte, _statiun, _metadata_KU, reg_selected, prov_selected, kab_selected);
                    var temp_data_ku = [];
                    for (var i in data) {
                        var row = data[i];
                        var row_ku = {};
                        row_ku["station_id"] = row.alias_station_id;
                        row_ku["station_name"] = row.station_name;
                        row_ku["latitude"] = row.current_latitude;
                        row_ku["longitude"] = row.current_longitude;
                        row_ku["periode"] = row.periode_form;
                        row_ku["tahun"] = row.tahun_form.substr(0, 4);
                        row_ku["tgl_pasang"] = row.tgl_pasang.replace("T", " ");
                        row_ku["tgl_angkat"] = row.tgl_angkat.replace("T", " ");
                        for (var k in row) {
                            // console.log("k : ",k)
                            if (
                                k == "alias_station_id" ||
                                k == "station_id" ||
                                k == "station_name" ||
                                k == "tgl_pasang" ||
                                k == "qc_flag" ||
                                k == "tgl_angkat" ||
                                k == "source_data" ||
                                k == "tahun_form" ||
                                k == "periode_form" ||
                                k == "qc_histories" ||
                                k == "current_latitude" ||
                                k == "current_longitude"
                            ) {
                                // console.log("continue : ",k)
                                continue;
                            } else {
                                // console.log("idak continue : ",k)
                                row_ku[k] = row[k];
                            }
                        }

                        row_ku["qc_flag"] = row.qc_flag;
                        if (row_ku["qc_flag"] == 1 || row_ku["qc_flag"] == 2) {
                            let JsonQc = JSON.parse(row.qc_histories);
                            let Qc_His = JsonQc.after;
                            var Log_QC_His = "";
                            for (var i_qc in Qc_His) {
                                Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                            }
                            let len = Log_QC_His.length;
                            let log = Log_QC_His;
                            if (len > 0) {
                                log = Log_QC_His.substring(0, len - 1);
                            }

                            row_ku["qc_parameter"] = log;
                        } else {
                            row_ku["qc_parameter"] = "";
                        }
                        temp_data_ku.push(row_ku);
                    }

                    this.STORE_EXPORT_PIAS = temp_data_ku;
                    this.items = temp_data_ku;
                    this.totalRows = this.items.length;
                    this.showLoading = false;
                } catch (e) {
                    this.showLoading = false;
                }
            } else {
                try {
                    if (currentFrom == "Thermohigograph" || currentFrom == "Barograph" || currentFrom == "HujanHellman") {
                        _metadata = _metadata + ",qc_flag,qc_histories";
                    }
                    const { data } = await outputexport.getRawData(currentFrom, date_gte, date_lte, _statiun, _metadata, reg_selected, prov_selected, kab_selected);
                    // console.log("PIAS");
                    var dataTemp = [];
                    for (var items in data) {
                        var dataRow = {};
                        // console.log("items : ",items);
                        //  console.log("data[items] : ",data[items]);
                        for (var item in data[items]) {
                            // console.log("item : ", item);
                            // console.log("data[items].item : ", data[items][item]);
                            if (item == "alias_station_id") {
                                continue;
                            } else if (item == "source_data") {
                                continue;
                            } else if (item == "station_id") {
                                dataRow["station_id"] = data[items]["alias_station_id"];
                            } else if (item == "data_timestamp") {
                                var data_timestamp = data[items][item];
                                dataRow["data_timestamp"] = data_timestamp.substr(0, 10);
                            } else if (item == "qc_histories") {
                                if (data[items].qc_flag == 1 || data[items].qc_flag == 2) {
                                    let JsonQc = JSON.parse(data[items][item]);
                                    let Qc_His = JsonQc.after;
                                    var Log_QC_His = "";
                                    for (var i_qc in Qc_His) {
                                        Log_QC_His = Log_QC_His + Qc_His[i_qc].atribut + ":" + Qc_His[i_qc].type + ",";
                                    }
                                    let len = Log_QC_His.length;
                                    let log = Log_QC_His;
                                    if (len > 0) {
                                        log = Log_QC_His.substring(0, len - 1);
                                    }

                                    dataRow["qc_parameter"] = log;
                                } else {
                                    dataRow["qc_parameter"] = "";
                                }
                            } else {
                                if (this.list_allias_parameters[item] != undefined) {
                                    var allias = this.list_allias_parameters[item].alias_parameter;
                                    // console.log("parameter : "+item+" allias : "+allias);
                                    dataRow[allias] = data[items][item];
                                } else {
                                    dataRow[item] = data[items][item];
                                }
                                // dataRow[field] = data[items][item];
                            }
                        }
                        dataTemp.push(dataRow);
                    }
                    // console.log("dataTemp : ", dataTemp);
                    this.STORE_EXPORT_PIAS = dataTemp;
                    this.items = dataTemp;
                    this.totalRows = this.items.length;
                    this.showLoading = false;
                } catch (e) {
                    console.log(e);
                    this.showLoading = false;
                }
            }
            let rowData = this.totalRows;

            this.sortDate = "Desc";

            if (rowData > 0) {
                this.ready_export = true;

                this.sortDate = "Desc";
            } else {
                this.ready_export = false;
                this.$swal({
                    title: "",
                    text: "Data Belum/Tidak Tersedia",
                    icon: "error",
                    customClass: {
                        confirmButton: "btn btn-danger",
                    },
                    buttonsStyling: false,
                });
            }
            // this.sortDate = "Asc";
            // this.showLoading = false
        },
        previewGroup() {
            this.cardCheckbox = [];
            this.selectedCheck = [];
            this.checkall = [];
            // this.col_card = this.temp_cols;
            // console.log("Selected ", this.selectedCheck);
            // console.log("leng grup : ", this.group.length);
            // console.log("grup : ", this.group);

            let leng_cols_current = this.group.length;
            if (leng_cols_current < 3) {
                this.col_card = 6;
            } else {
                this.col_card = 4;
            }
            for (var obj in this.group) {
                var attrib = [];
                var groupData = parseInt(this.group[obj].value);
                // console.log("objenya : ", groupData);
                for (var atrb in this.arr_group[groupData].attr) {
                    var row = {
                        text: this.arr_group[groupData].attr[atrb],
                        value: atrb,
                    };
                    // console.log(row)
                    attrib.push(row);
                }
                // console.log("attrib : ",attrib)
                var data = {
                    label: this.arr_group[groupData].label,
                    attr: attrib,
                };
                this.cardCheckbox.push(data);
                this.sumbitShow = true;
                // console.log("data : ",data)
            }
        },
        showParamaterGroup(grs) {
            for (const obj in grs) {
                const attrib = [];
                const groupData = parseInt(grs[obj].value);
                for (const atrb in this.arr_group[groupData].attr) {
                    const row = {
                        text: this.arr_group[groupData].attr[atrb],
                        value: atrb,
                    };
                    attrib.push(row);
                }

                const data = {
                    label: this.arr_group[groupData].label,
                    attr: attrib,
                    index_order: groupData,
                };
                this.cardCheckbox.push(data);
                // console.log("data : ", data);
            }
        },
        hideParamaterGroup(grs) {
            // remove from this.selectedCheck
            const toRemoveFromSelected = this.cardCheckbox.filter((cc) => {
                for (const ind in grs) {
                    if (grs[ind].text === cc.label) {
                        return true;
                    }
                }
                return false;
            });
            toRemoveFromSelected.forEach((gr) => this.uncheckAllItems(gr));

            // remove group param card
            this.cardCheckbox = this.cardCheckbox.filter((cc) => {
                for (const ind in grs) {
                    if (grs[ind].text === cc.label) {
                        return false;
                    }
                }
                return true;
            });
        },
        checkAllItems(gr) {
            const row = gr.attr;
            row.forEach((elem) => {
                if (this.selectedCheck.indexOf(elem.value) == "-1") {
                    this.selectedCheck.push(elem.value);
                }
            });
        },
        uncheckAllItems(gr) {
            const row = gr.attr;
            // console.log("row", gr);
            row.forEach((elem) => {
                const index = this.selectedCheck.indexOf(elem.value);
                if (index > -1) {
                    this.selectedCheck.splice(index, 1);
                }
            });
        },
        checkAllGroups() {
            this.cardCheckbox.forEach((obj) => {
                this.checkAllItems(obj);
            });
        },
        uncheckAllGroups() {
            this.selectedCheck = [];
        },
        // exportPdf() {
        //   this.PDF_SELECTED = "PIAS";
        //   this.showLoading = true;
        //   setTimeout(() => {
        //     this.$refs.html2Pdf.generatePdf();
        //     this.showLoading = false;
        //   }, 5000);

        //   // this.$refs.html2Pdf.generatePdf()
        // },

        //
        // img() {
        //   <b-row>
        //     <b-col cols="1"> </b-col>
        //     <b-col cols="4" style="font-size: 7.5px; border: 1px solid #666666">
        //       {{ dislaimer }}
        //     </b-col>
        //     <b-col cols="2" style="font-size: 8px bold">
        //       // <img :src="ttd_digital" style="width: 80px; height: 40px" /><br />
        //       {{ pejabat_nama }}
        //     </b-col>
        //   </b-row>
        // },
        exportPdfRawPibal() {
            // console.log("t_form_opt exportPdfRawPibal", this.t_form);

            if (this.t_form.value === "Agm1a") {
                this.dataAlias = this.myAliasAgm1a;
            } else if (this.t_form.value == "Agm1b") {
                this.dataAlias = this.myAliasAgm1b;
            } else if (this.t_form.value === "Aktinograph") {
                this.dataAlias = this.myAliasAktinograph;
            } else if (this.t_form.value === "Barograph") {
                this.dataAlias = this.myAliasBarograph;
            } else if (this.t_form.value === "Fenologi") {
                this.dataAlias = this.myAliasFenologi;
            } else if (this.t_form.value === "Fklim") {
                this.dataAlias = this.myAliasFklim71;
            } else if (this.t_form.value === "FormHujan") {
                this.dataAlias = this.myAliasFormHujan;
            } else if (this.t_form.value === "HujanHellman") {
                this.dataAlias = this.myAliasFormHujanHelman;
            } else if (this.t_form.value === "KelembabanTanah") {
                this.dataAlias = this.myAliasFormKelembapanTanah;
            } else if (this.t_form.value === "FormPerawanan") {
                this.dataAlias = this.myAliasFormPerawanan;
            } else if (this.t_form.value === "Gunbellani") {
                this.dataAlias = this.myAliasGunBellani;
            } else if (this.t_form.value === "IklimMikro") {
                this.dataAlias = this.myAliasIklimMikro;
            } else if (this.t_form.value === "LamaPenyinaran") {
                this.dataAlias = this.myAliasLamaPenyinaran;
            } else if (this.t_form.value === "Lysimeter") {
                this.dataAlias = this.myAliasLysimeter;
            } else if (this.t_form.value === "OpPenguapan") {
                this.dataAlias = this.myAliasOpPenguapan;
            } else if (this.t_form.value === "Pibal") {
                // "MetarSpeci" "sinoptik"
                this.dataAlias = this.myAliasPibal;
            } else if (this.t_form.value === "PichePenguapan") {
                this.dataAlias = this.myAliasPichePenguapan;
            } else if (this.t_form.value === "PsycrometerAssman") {
                this.dataAlias = this.myAliasPsychrometerAssman;
            } else if (this.t_form.value === "SuhuTanah") {
                this.dataAlias = this.myAliasSuhuTanah;
            } else if (this.t_form.value === "Thermohigograph") {
                this.dataAlias = this.myAliasThermohigroghraph;
            }
            // console.log("this.dataAlias", this.dataAlias);

            function renameKeys(csv_raw_pibal, dataAlias) {
                const keyValues = Object.keys(csv_raw_pibal).map((key) => {
                    const newKey = dataAlias[key] || key;
                    return { [newKey]: csv_raw_pibal[key] };
                });
                return Object.assign({}, ...keyValues);
            }

            const renamedObj = renameKeys(this.csv_raw_pibal[0], this.dataAlias);
            this.dataFinal = renamedObj;

            // console.log("renamedObj", renamedObj);
            // console.log("data", this.dataFinal);

            var columns = [Object.keys(renamedObj)];
            var rows = this.csv_raw_pibal.map((elemento) => Object.values(elemento));

            var doc = new jsPDF("l", "pt");
            var title = "Raw Pibal";
            var dislaimer = this.dislaimer;
            var ttd_digital = this.ttd_digital;
            var pejabat_nama = this.pejabat_nama;
            var pejabat_jabatan = this.pejabat_jabatan;

            // doc.autoTable({
            //   showHead: "everyPage",
            //   didDrawPage: function (data) {
            //     //   // Header
            //     doc.setFontSize(12);
            //     doc.setTextColor(40);
            //     doc.text(400, 20, title, "center");
            //   },
            //   theme: "grid",
            //   tableWidth: "auto",
            //   head: columns,

            //   headStyles: {
            //     fontSize: 8,
            //     textColor: "black",
            //     fillColor: [211, 211, 211],
            //     lineWidth: 0.5,
            //     halign: "center",
            //     valign: "middle",
            //     lineColor: "black",
            //     cellPadding: 2,
            //   },
            //   bodyStyles: {
            //     halign: "center",
            //     lineWidth: 0.5,
            //     lineColor: "black",
            //   },
            //   lineColor: [0, 0, 0],

            //   body: rows,
            //   margin: { top: 40, bottom: 80, left: 15, right: 15 },
            //   horizontalPageBreak: true,
            //   horizontalPageBreakRepeat: 0,
            //   styles: { fontSize: 8 },
            // });
            // // var finalY = doc.lastAutoTable.finalY
            // // doc.text('After reset (blue header)', 15, finalY + 15)
            // doc.setFontSize(8);
            // // doc.setTextColor(40);
            // // doc.text(400, 20, title, "center");

            // // Footer
            // doc.text(50, 540, dislaimer);
            // doc.rect(42, 520, 170, 60, "S");
            // doc.text(700, 528, pejabat_jabatan);
            // doc.addImage(ttd_digital, "JPEG", 690, 530, 70, 40);
            // doc.text(700, 579, pejabat_nama);
            doc.autoTable({
                showHead: "everyPage",
                didDrawPage: function (data) {
                    //   // Header
                    doc.setFontSize(12);
                    doc.setTextColor(40);
                    doc.text(400, 20, title, "center");
                },
                theme: "grid",
                tableWidth: "auto",
                head: columns,
                headStyles: {
                    fontSize: 8,
                    textColor: "black",
                    fillColor: [211, 211, 211],
                    lineWidth: 0.5,
                    halign: "center",
                    valign: "middle",
                    lineColor: "black",
                    cellPadding: 2,
                },
                bodyStyles: {
                    halign: "center",
                    lineWidth: 0.5,
                    lineColor: "black",
                },
                lineColor: [0, 0, 0],
                body: rows,
                margin: { top: 40, bottom: 80, left: 15, right: 15 },
                horizontalPageBreak: true,
                styles: { fontSize: 8, cellWidth: "wrap" },
            });

            // Footer
            var HalTerakhir = doc.lastAutoTable.finalY;
            doc.setFontSize(8);
            const wrap = (str) => {
                var result = "";
                while (str.length > 0) {
                    result += str.substring(0, 167) + "\n";
                    str = str.substring(167);
                }
                return result;
            };

            const wrapText = wrap(dislaimer);

            var hitungNL = wrapText.split(/\r?\n|\r|\n/g).length;
            var rowHeight = hitungNL * 9.2;

            const hitungWidth = wrapText
                .toString()
                .split(/\r?\n|\r|\n/g)
                .map(({ length }) => length);
            const maxWidth = Math.max(...hitungWidth);
            const finalWidth = maxWidth * 3.9;

            if (finalWidth > 598) {
                doc.rect(40, HalTerakhir + 15, 598 + 10, rowHeight + 5, "S");
                doc.text(wrapText, 45, HalTerakhir + 25, {
                    maxWidth: 598,
                });
            } else {
                doc.rect(40, HalTerakhir + 15, finalWidth + 10, rowHeight + 5, "S");
                doc.text(wrapText, 45, HalTerakhir + 25);
            }

            doc.text(pejabat_jabatan, 700, HalTerakhir + 15);
            doc.addImage(ttd_digital, "JPEG", 680, HalTerakhir + 20, 80, 30);
            doc.text(pejabat_nama, 700, HalTerakhir + 65);
            var leng = this.rawpibalname.length;
            var namefile = this.rawpibalname.substr(0, leng - 4);
            doc.save(namefile + ".pdf");
        },
        exportPdfDataPibal() {
            // console.log("t_form_opt exportPdfDataPibal", this.t_form);

            if (this.t_form.value === "Agm1a") {
                this.dataAlias = this.myAliasAgm1a;
            } else if (this.t_form.value == "Agm1b") {
                this.dataAlias = this.myAliasAgm1b;
            } else if (this.t_form.value === "Aktinograph") {
                this.dataAlias = this.myAliasAktinograph;
            } else if (this.t_form.value === "Barograph") {
                this.dataAlias = this.myAliasBarograph;
            } else if (this.t_form.value === "Fenologi") {
                this.dataAlias = this.myAliasFenologi;
            } else if (this.t_form.value === "Fklim") {
                this.dataAlias = this.myAliasFklim71;
            } else if (this.t_form.value === "FormHujan") {
                this.dataAlias = this.myAliasFormHujan;
            } else if (this.t_form.value === "HujanHellman") {
                this.dataAlias = this.myAliasFormHujanHelman;
            } else if (this.t_form.value === "KelembabanTanah") {
                this.dataAlias = this.myAliasFormKelembapanTanah;
            } else if (this.t_form.value === "FormPerawanan") {
                this.dataAlias = this.myAliasFormPerawanan;
            } else if (this.t_form.value === "Gunbellani") {
                this.dataAlias = this.myAliasGunBellani;
            } else if (this.t_form.value === "IklimMikro") {
                this.dataAlias = this.myAliasIklimMikro;
            } else if (this.t_form.value === "LamaPenyinaran") {
                this.dataAlias = this.myAliasLamaPenyinaran;
            } else if (this.t_form.value === "Lysimeter") {
                this.dataAlias = this.myAliasLysimeter;
            } else if (this.t_form.value === "OpPenguapan") {
                this.dataAlias = this.myAliasOpPenguapan;
            } else if (this.t_form.value === "Pibal") {
                // "MetarSpeci" "sinoptik"
                this.dataAlias = this.myAliasPibal;
            } else if (this.t_form.value === "PichePenguapan") {
                this.dataAlias = this.myAliasPichePenguapan;
            } else if (this.t_form.value === "PsycrometerAssman") {
                this.dataAlias = this.myAliasPsychrometerAssman;
            } else if (this.t_form.value === "SuhuTanah") {
                this.dataAlias = this.myAliasSuhuTanah;
            } else if (this.t_form.value === "Thermohigograph") {
                this.dataAlias = this.myAliasThermohigroghraph;
            }
            // console.log("this.dataAlias", this.dataAlias);

            function renameKeys(csv_data_pibal, dataAlias) {
                const keyValues = Object.keys(csv_data_pibal).map((key) => {
                    const newKey = dataAlias[key] || key;
                    return { [newKey]: csv_data_pibal[key] };
                });
                return Object.assign({}, ...keyValues);
            }

            const renamedObj = renameKeys(this.csv_data_pibal[0], this.dataAlias);
            this.dataFinal = renamedObj;

            // console.log("renamedObj", renamedObj);
            // console.log("data", this.dataFinal);

            var columns = [Object.keys(renamedObj)];
            var rows = this.csv_data_pibal.map((elemento) => Object.values(elemento));
            var doc = new jsPDF("l", "pt");
            var title = "Data Pibal";
            var dislaimer = this.dislaimer;
            var ttd_digital = this.ttd_digital;
            var pejabat_nama = this.pejabat_nama;
            var pejabat_jabatan = this.pejabat_jabatan;

            // doc.autoTable({
            //   showHead: "everyPage",
            //   didDrawPage: function (data) {
            //     //   // Header
            //     doc.setFontSize(12);
            //     doc.setTextColor(40);
            //     doc.text(400, 20, title, "center");

            //     //   // Footer
            //     //   doc.text(50, 540, dislaimer);
            //     //   doc.rect(42, 520, 170, 60, "S");

            //     //   doc.text(700, 528, pejabat_jabatan);

            //     //   doc.addImage(ttd_digital, "JPEG", 690, 530, 70, 40);
            //     //   doc.text(700, 579, pejabat_nama);
            //   },
            //   theme: "grid",
            //   tableWidth: "auto",
            //   head: columns,

            //   headStyles: {
            //     fontSize: 8,
            //     textColor: "black",
            //     fillColor: [211, 211, 211],
            //     lineWidth: 0.5,
            //     halign: "center",
            //     valign: "middle",
            //     lineColor: "black",
            //     cellPadding: 2,
            //   },
            //   bodyStyles: {
            //     halign: "center",
            //     lineWidth: 0.5,
            //     lineColor: "black",
            //   },
            //   lineColor: [0, 0, 0],

            //   body: rows,
            //   margin: { top: 40, bottom: 80, left: 15, right: 15 },
            //   horizontalPageBreak: true,
            //   horizontalPageBreakRepeat: 0,
            //   styles: { fontSize: 8 },
            // });
            // // var finalY = doc.lastAutoTable.finalY
            // // doc.text('After reset (blue header)', 15, finalY + 15)
            // doc.setFontSize(8);
            // // doc.setTextColor(40);
            // // doc.text(400, 20, title, "center");

            // // Footer
            // doc.text(50, 540, dislaimer);
            // doc.rect(42, 520, 170, 60, "S");
            // doc.text(700, 528, pejabat_jabatan);
            // doc.addImage(ttd_digital, "JPEG", 690, 530, 70, 40);
            // doc.text(700, 579, pejabat_nama);

            doc.autoTable({
                showHead: "everyPage",
                didDrawPage: function (data) {
                    //   // Header
                    doc.setFontSize(12);
                    doc.setTextColor(40);
                    doc.text(400, 20, title, "center");
                },
                theme: "grid",
                tableWidth: "auto",
                head: columns,
                headStyles: {
                    fontSize: 8,
                    textColor: "black",
                    fillColor: [211, 211, 211],
                    lineWidth: 0.5,
                    halign: "center",
                    valign: "middle",
                    lineColor: "black",
                    cellPadding: 2,
                },
                bodyStyles: {
                    halign: "center",
                    lineWidth: 0.5,
                    lineColor: "black",
                },
                lineColor: [0, 0, 0],
                body: rows,
                margin: { top: 40, bottom: 80, left: 15, right: 15 },
                horizontalPageBreak: true,
                styles: { fontSize: 8, cellWidth: "wrap" },
            });

            // Footer
            var HalTerakhir = doc.lastAutoTable.finalY;
            doc.setFontSize(8);
            const wrap = (str) => {
                var result = "";
                while (str.length > 0) {
                    result += str.substring(0, 167) + "\n";
                    str = str.substring(167);
                }
                return result;
            };

            const wrapText = wrap(dislaimer);

            var hitungNL = wrapText.split(/\r?\n|\r|\n/g).length;
            var rowHeight = hitungNL * 9.2;

            const hitungWidth = wrapText
                .toString()
                .split(/\r?\n|\r|\n/g)
                .map(({ length }) => length);
            const maxWidth = Math.max(...hitungWidth);
            const finalWidth = maxWidth * 3.9;

            if (finalWidth > 598) {
                doc.rect(40, HalTerakhir + 15, 598 + 10, rowHeight + 5, "S");
                doc.text(wrapText, 45, HalTerakhir + 25, {
                    maxWidth: 598,
                });
            } else {
                doc.rect(40, HalTerakhir + 15, finalWidth + 10, rowHeight + 5, "S");
                doc.text(wrapText, 45, HalTerakhir + 25);
            }

            doc.text(pejabat_jabatan, 700, HalTerakhir + 15);
            doc.addImage(ttd_digital, "JPEG", 680, HalTerakhir + 20, 80, 30);
            doc.text(pejabat_nama, 700, HalTerakhir + 65);

            var namefile = this.datapibalname.substr(0, this.datapibalname.length - 4);
            doc.save(namefile + ".pdf");
        },
        exportPdf() {
            // console.log("t_form_opt", this.t_form);

            if (this.t_form.value === "Agm1a") {
                this.dataAlias = this.myAliasAgm1a;
            } else if (this.t_form.value == "Agm1b") {
                this.dataAlias = this.myAliasAgm1b;
            } else if (this.t_form.value === "Aktinograph") {
                this.dataAlias = this.myAliasAktinograph;
            } else if (this.t_form.value === "Barograph") {
                this.dataAlias = this.myAliasBarograph;
            } else if (this.t_form.value === "Fenologi") {
                this.dataAlias = this.myAliasFenologi;
            } else if (this.t_form.value === "Fklim") {
                this.dataAlias = this.myAliasFklim71;
            } else if (this.t_form.value === "FormHujan") {
                this.dataAlias = this.myAliasFormHujan;
            } else if (this.t_form.value === "HujanHellman") {
                this.dataAlias = this.myAliasFormHujanHelman;
            } else if (this.t_form.value === "KelembabanTanah") {
                this.dataAlias = this.myAliasFormKelembapanTanah;
            } else if (this.t_form.value === "FormPerawanan") {
                this.dataAlias = this.myAliasFormPerawanan;
            } else if (this.t_form.value === "Gunbellani") {
                this.dataAlias = this.myAliasGunBellani;
            } else if (this.t_form.value === "IklimMikro") {
                this.dataAlias = this.myAliasIklimMikro;
            } else if (this.t_form.value === "LamaPenyinaran") {
                this.dataAlias = this.myAliasLamaPenyinaran;
            } else if (this.t_form.value === "Lysimeter") {
                this.dataAlias = this.myAliasLysimeter;
            } else if (this.t_form.value === "OpPenguapan") {
                this.dataAlias = this.myAliasOpPenguapan;
            } else if (this.t_form.value === "Pibal") {
                // "MetarSpeci" "sinoptik"
                this.dataAlias = this.myAliasPibal;
            } else if (this.t_form.value === "PichePenguapan") {
                this.dataAlias = this.myAliasPichePenguapan;
            } else if (this.t_form.value === "PsycrometerAssman") {
                this.dataAlias = this.myAliasPsychrometerAssman;
            } else if (this.t_form.value === "SuhuTanah") {
                this.dataAlias = this.myAliasSuhuTanah;
            } else if (this.t_form.value === "Thermohigograph") {
                this.dataAlias = this.myAliasThermohigroghraph;
            }
            // OPTION 1
            var aa = renamedObj;
            // console.log("data", this.STORE_EXPORT_PIAS);

            const bb = this.STORE_EXPORT_PIAS.map((el) => Object.fromEntries(Object.entries(el).map(([key, value]) => [key.replace(/_+/g, "\n"), value])));
            var cc = Object.keys(bb[0]);
            var columns = [Object.keys(bb[0])];
            // OPTION 1

            // OPTION 2
            // function renameKeys(STORE_EXPORT_PIAS, newKeys) {
            //   const keyValues = Object.keys(STORE_EXPORT_PIAS).map((key) => {
            //     const newKey = newKeys[key] || key;
            //     return { [newKey]: STORE_EXPORT_PIAS[key] };
            //   });
            //   return Object.assign({}, ...keyValues);
            // }

            // const newKeys = {
            //   station_id: "station_id",
            //   station_name: "station_name",
            //   current_latitude: "latitude",
            //   current_longitude: "longitude",
            //   data_timestamp: "timestamp",
            //   rad_actino_5_6: "rad_5_6",
            //   rad_actino_6_7: "rad_6_7",
            //   rad_actino_7_8: "rad_7_8",
            //   rad_actino_8_9: "rad_8_9",
            //   rad_actino_9_10: "rad_9_10",
            //   rad_actino_10_11: "rad_10_11",
            //   rad_actino_11_12: "rad_11_12",
            //   rad_actino_12_13: "rad_12_13",
            //   rad_actino_13_14: "rad_13_14",
            //   rad_actino_14_15: "rad_14_15",
            //   rad_actino_15_16: "rad_15_16",
            //   rad_actino_16_17: "rad_16_17",
            //   rad_actino_17_18: "rad_17_18",
            //   rad_actino_18_19: "rad_18_19",
            //   rad_actino_19_20: "rad_19_20",
            //   rad_actino_20_21: "rad_20_21",
            //   rad_actino_21_22: "rad_21_22",
            //   rad_actino_22_23: "rad_22_23",
            //   rad_actino_23_24: "rad_23_24",
            //   rad_actino_total: "rad_total",
            // };

            // const renamedObj = renameKeys(this.STORE_EXPORT_PIAS[0], newKeys);
            // console.log("renamedObj", renamedObj)
            // var columns = [Object.keys(renamedObj)]
            // OPTION 2

            // OPTION 3
            // console.log("this.dataAlias", this.dataAlias);

            function renameKeys(STORE_EXPORT_PIAS, dataAlias) {
                const keyValues = Object.keys(STORE_EXPORT_PIAS).map((key) => {
                    const newKey = dataAlias[key] || key;
                    return { [newKey]: STORE_EXPORT_PIAS[key] };
                });
                return Object.assign({}, ...keyValues);
            }

            const renamedObj = renameKeys(this.STORE_EXPORT_PIAS[0], this.dataAlias);
            this.dataFinal = renamedObj;

            // console.log("this.STORE_EXPORT_PIAS", this.STORE_EXPORT_PIAS);
            // console.log("myAliasAktinograph", this.myAliasAktinograph);
            // console.log("data", this.dataFinal);
            // var columns = [Object.keys(renamedObj)]
            // OPTION 3
            // key = 'type';
            // const index_parameter_qc = renamedObj.indexOf(item => Object.keys(item) == "qc_parameter")

            // const cv = this.STORE_EXPORT_PIAS.indexOf(index_parameter_qc)
            // console.log("cv", index_parameter_qc);
            const obj_key = Object.keys(this.STORE_EXPORT_PIAS[0]);
            var idx_qcparameter = obj_key.indexOf("qc_parameter");
            var idx_qcparameter1 = 42;
            // console.log("obj_key", typeof idx_qcparameter);
            // console.log("indx", idx_qcparameter);

            // OPTION 0
            // var aa = Object.keys(this.STORE_EXPORT_PIAS[0])
            // var columns = [aa];
            // OPTION 0

            var rows = this.STORE_EXPORT_PIAS.map((elemento) => Object.values(elemento));
            var doc = new jsPDF("l", "pt");
            var title = this.form_name_exp_pdf.text;
            var dislaimer = this.dislaimer;
            var ttd_digital = this.ttd_digital;
            var pejabat_nama = this.pejabat_nama;
            var pejabat_jabatan = this.pejabat_jabatan;

            var hash1 = {};
            var hash2 = {};
            var hash3 = {};
            var hash4 = {};

            // HEADER STATION ID
            var rows_station_id = this.STORE_EXPORT_PIAS.map(function (obj) {
                return obj.station_id;
            }).filter(function (station_id) {
                if (!hash1[station_id]) {
                    hash1[station_id] = true;
                    return true;
                }
                return false;
            });

            // HEADER STATION NAME
            var rows_station_name = this.STORE_EXPORT_PIAS.map(function (obj) {
                return obj.station_name;
            }).filter(function (station_name) {
                if (!hash2[station_name]) {
                    hash2[station_name] = true;
                    return true;
                }
                return false;
            });

            // HEADER LATITUDE
            var rows_current_latitude = this.STORE_EXPORT_PIAS.map(function (obj) {
                return obj.current_latitude;
            }).filter(function (current_latitude) {
                if (!hash3[current_latitude]) {
                    hash3[current_latitude] = true;
                    return true;
                }
                return false;
            });

            // HEADER LONGITUDE
            var rows_current_longitude = this.STORE_EXPORT_PIAS.map(function (obj) {
                return obj.current_longitude;
            }).filter(function (current_longitude) {
                if (!hash4[current_longitude]) {
                    hash4[current_longitude] = true;
                    return true;
                }
                return false;
            });

            doc.autoTable({
                showHead: "everyPage",
                didDrawPage: function (data) {
                    //   // Header
                    doc.setFontSize(12);
                    doc.setTextColor(40);
                    doc.text(400, 20, title, "center");

                    if (data === "qc_parameters") {
                        // doc.setFontSize(7);
                        // doc.text(15, 30, "Id Stasiun :", "left");
                        // doc.text(55, 30, rows_station_id, "left");
                    }

                    // doc.setFontSize(7);

                    // doc.text(15, 30, "Id Stasiun :", "left");
                    // doc.text(55, 30, rows_station_id, "left");

                    // doc.text(15, 40, "Nama Stasiun :", "left");
                    // doc.text(65, 40, rows_station_name, "left");

                    // doc.text(765, 30, "Latitude :", "right");
                    // doc.text(795, 30, rows_current_latitude, "right");

                    // doc.text(765, 40, "Longitude :", "right");
                    // doc.text(795, 40, rows_current_longitude, "right");

                    //   // Footer
                    //   doc.text(50, 540, dislaimer);
                    //   doc.rect(42, 520, 170, 60, "S");
                    //   doc.text(700, 528, pejabat_jabatan);
                    //   doc.addImage(ttd_digital, "JPEG", 690, 530, 70, 40);
                    //   doc.text(700, 579, pejabat_nama);
                },
                theme: "grid",
                tableWidth: "auto",
                head: columns,
                headStyles: {
                    fontSize: 8,
                    textColor: "black",
                    fillColor: [211, 211, 211],
                    lineWidth: 0.5,
                    halign: "center",
                    valign: "middle",
                    lineColor: "black",
                    cellPadding: 2,
                    // maxCellWidth: 300
                },
                bodyStyles: {
                    halign: "center",
                    lineWidth: 0.5,
                    lineColor: "black",
                    // maxCellWidth: 400
                },
                lineColor: [0, 0, 0],
                // console.log("idx_qcparameter", idx_qcparameter),
                columnStyles: {
                    // idx_qcparameter: { cellWidth: 100 },
                    // idx_qcparameter1: { columnWidth: 240 },
                    // 42: { cellWidth: 80 },
                    // 43: { cellWidth: 80 },
                    // 2: { cellWidth: 80 },
                    // etc
                },
                // columnStyles: { current_latitude: { cellWidth: 'wrap' }, ["current longitude"]: { cellWidth: 'wrap' } },
                body: rows,
                margin: { top: 60, bottom: 80, left: 15, right: 15 },
                horizontalPageBreak: true,
                // horizontalPageBreakRepeat: 0,
                styles: { fontSize: 8, cellWidth: "wrap" },
            });

            // Footer
            var HalTerakhir = doc.lastAutoTable.finalY;
            doc.setFontSize(8);

            // var widthDisclaimer = doc.getTextWidth(dislaimer);
            // const wrapText = dislaimer.replace(/.{590}/g, '\n')

            const wrap = (str) => {
                var result = "";
                while (str.length > 0) {
                    result += str.substring(0, 167) + "\n";
                    str = str.substring(167);
                }
                return result;
            };

            const wrapText = wrap(dislaimer);

            var hitungNL = wrapText.split(/\r?\n|\r|\n/g).length;
            var rowHeight = hitungNL * 9.2;

            const hitungWidth = wrapText
                .toString()
                .split(/\r?\n|\r|\n/g)
                .map(({ length }) => length);
            const maxWidth = Math.max(...hitungWidth);
            const finalWidth = maxWidth * 3.9;

            if (finalWidth > 598) {
                doc.rect(40, HalTerakhir + 15, 598 + 10, rowHeight + 5, "S");
                doc.text(wrapText, 45, HalTerakhir + 25, {
                    maxWidth: 598,
                });
            } else {
                doc.rect(40, HalTerakhir + 15, finalWidth + 10, rowHeight + 5, "S");
                doc.text(wrapText, 45, HalTerakhir + 25);
            }

            doc.text(pejabat_jabatan, 700, HalTerakhir + 15);
            doc.addImage(ttd_digital, "JPEG", 680, HalTerakhir + 20, 80, 30);
            doc.text(pejabat_nama, 700, HalTerakhir + 65);

            // Footer
            // doc.text(50, 540, dislaimer);
            // doc.rect(42, 520, 170, 60, "S");
            // doc.text(700, 528, pejabat_jabatan);
            // doc.addImage(ttd_digital, "JPEG", 690, 530, 70, 40);
            // doc.text(700, 579, pejabat_nama);
            doc.save(this.form_name_exp_pdf.text + ".pdf");
        },
        async listBalai() {
            try {
                const { data } = await metadata.getRegionList();
                for (var i = 0; i < data.length; i++) {
                    const dataRegion = {
                        value: data[i].region_id,
                        text: data[i].region_description,
                    };
                    this.regions_opt.push(dataRegion);
                }
            } catch (e) {
                console.log(e);
            }
        },
        async listPropinsi(region_id) {
            this.propinsi_opt = [{ value: "", text: "Semua Propinsi" }];
            try {
                const { data } = await metadata.getPropinsiListByRegion(region_id);
                // console.log(data);
                for (var i = 0; i < data.length; i++) {
                    const rowsPropinsi = {
                        value: data[i].propinsi_id,
                        text: data[i].propinsi_name,
                    };
                    this.propinsi_opt.push(rowsPropinsi);

                    // this.items.push(dataPropinsi);
                }
            } catch (e) {
                console.log(e);
            }
        },
        async listKabupaten(propinsi_id) {
            if (propinsi_id != "") {
                this.stasiun_opt = [];
                this.kabupaten_opt = [{ value: "", text: "Semua Kota/Kabupaten" }];
                try {
                    const { data } = await metadata.getKabupatenListByPropinsi(propinsi_id);
                    // console.log(data);
                    for (var i = 0; i < data.length; i++) {
                        const rowsKabupaten = {
                            value: data[i].kabupaten_id,
                            text: data[i].kabupaten_name,
                        };
                        this.kabupaten_opt.push(rowsKabupaten);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        },
        async listStasiun(kab_id) {
            if (kab_id == "") {
                console.log("kab kosong");
            } else {
                this.showLoading = true;
                this.stasiun_opt = [{ value: "", text: "Semua Stasiun" }];
                try {
                    const { data } = await metadata.getExStasiunByKab(kab_id, this.is_bmkg_entry);
                    // const { data } = await metadata.getStasiunByKabupaten(kab_id);
                    // console.log(data);
                    for (var i = 0; i < data.length; i++) {
                        const rowsStasiun = {
                            value: data[i].station_id,
                            text: data[i].station_name,
                        };
                        this.stasiun_opt.push(rowsStasiun);
                    }
                    var sort_data = this.stasiun_opt;
                    sort_data.sort((a, b) => {
                        let fa = a.text;
                        let fb = b.text;

                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    });
                    this.stasiun_opt = sort_data;
                    this.showLoading = false;
                } catch (e) {
                    console.log(e);
                    this.showLoading = false;
                }
            }
        },
        async listStasiunReg(reg_id) {
            this.showLoading = true;
            this.stasiun_opt = [{ value: "", text: "Semua Stasiun" }];
            try {
                // const { data } = await metadata.getStasiunByRegion(reg_id);
                const { data } = await metadata.getExStasiunByBalai(reg_id, this.is_bmkg_entry);
                // console.log(data);
                for (var i = 0; i < data.length; i++) {
                    const rowsStasiun = {
                        value: data[i].station_id,
                        text: data[i].station_name,
                    };
                    this.stasiun_opt.push(rowsStasiun);
                }
                var sort_data = this.stasiun_opt;
                // console.log("sort : ", sort_data)
                sort_data.sort((a, b) => {
                    let fa = a.text;
                    let fb = b.text;

                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                });
                this.stasiun_opt = sort_data;
                this.showLoading = false;
            } catch (e) {
                console.log(e);
                this.showLoading = false;
            }
        },
        async listStasiunProv(prov_id) {
            // console.log("prov_id : ",prov_id)
            if (prov_id == "") {
                console.log("prov_id kosong");
            } else {
                this.showLoading = true;
                this.stasiun_opt = [{ value: "", text: "Semua Stasiun" }];
                try {
                    const { data } = await metadata.getExStasiunByProv(prov_id, this.is_bmkg_entry);
                    // const { data } = await metadata.getStasiunByPropinsi(prov_id);
                    // console.log(data);
                    for (var i = 0; i < data.length; i++) {
                        const rowsStasiun = {
                            value: data[i].station_id,
                            text: data[i].station_name,
                        };
                        this.stasiun_opt.push(rowsStasiun);
                    }
                    var sort_data = this.stasiun_opt;
                    sort_data.sort((a, b) => {
                        let fa = a.text;
                        let fb = b.text;

                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    });
                    this.stasiun_opt = sort_data;
                    this.showLoading = false;
                } catch (e) {
                    console.log(e);
                    this.showLoading = false;
                }
            }
        },

        getListPejabat() {
            var token = jwt.getToken();
            var url = helper.getBaseUrl() + "/@search?type_name=Esign&esign_status=1";
            var config = {
                method: "get",
                url: url,
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer  ${token}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
                    "Access-Control-Allow-Credentials": true,
                },
            };
            this.showLoading = true;
            this.pejabat_opt = [];
            axios(config)
                .then((response) => {
                    // console.log(response.data.items);
                    var row_up = {};
                    // console.log("res : ",response.data.items)
                    for (var i in response.data.items) {
                        var data = response.data.items[i];
                        var ttd_digital_local = helper.getNotImages();
                        //
                        // console.log("ttd na : ", data);
                        if (data.ttd_digital != null) {
                            ttd_digital_local = "data:image/jpeg;base64," + data.ttd_digital;
                        }
                        i++;
                        var row = {
                            no: i,
                            nip: data.nip,
                            nama_pejabat: data.nama_pejabat,
                            jabatan: data.jabatan,
                            disclaimer: data.disclaimer,
                            ttd_digital: ttd_digital_local,
                            status: data.esign_status,
                            path: data.path,
                            pejabat_id: data.id,
                        };
                        this.pejabat_opt.push({
                            text: data.nama_pejabat + " - " + data.jabatan,
                            value: data.path,
                        });
                        row_up[data.path] = row;
                    }
                    this.row_esign = row_up;

                    this.showLoading = false;
                })
                .catch((error) => {
                    console.log("errornya : ", error);
                    this.showLoading = false;
                });
        },
        beforeDownload($event) {},
        onProgress($event) {},
        hasStartedGeneration() {},
        hasGenerated($event) {},
    },
    watch: {
        sortDate(val) {
            if (val == "Desc") {
                var data = this.items;
                // data.sort(function (a, b) {
                //   return new Date(b.data_timestamp) - new Date(a.data_timestamp) ;
                // });
                data.sort((a, b) => {
                    var station_name = a.station_name.localeCompare(b.station_name);
                    var datestam = new Date(a.data_timestamp) - new Date(b.data_timestamp);
                    return station_name || -datestam;
                });
                this.items = data;
            } else if (val == "Asc") {
                var data = this.items;
                // data.sort(function (a, b) {
                //   return new Date(a.data_timestamp) - new Date(b.data_timestamp);
                // });
                data.sort((a, b) => {
                    var station_name = a.station_name.localeCompare(b.station_name);
                    var datestam = new Date(b.data_timestamp) - new Date(a.data_timestamp);
                    return station_name || -datestam;
                });
                this.items = data;
            }
        },
        pejabat(val) {
            var data = this.row_esign[val.value];
            this.pejabat_id = data.pejabat_id;
            this.pejabat_nama = data.nama_pejabat;
            this.pejabat_jabatan = data.jabatan;
            this.dislaimer = data.disclaimer;
            this.ttd_digital = data.ttd_digital;
            // console.log(data)
        },
        checkall() {
            // console.log("cek : ", this.selectedCheck);
            // Helper.DELETE_ARRAY(this.selectedCheck,)
            this.selectedCheck = [];

            for (var obj in this.checkall) {
                var cek = this.checkall[obj];
                var is_data = this.uncheckall.indexOf(cek);
                if (is_data != "-1") {
                    this.uncheckall.splice(is_data, 1);
                }
                var row = this.checkall[obj].attr;
                for (var i in row) {
                    if (this.selectedCheck.indexOf(row[i].value) == "-1") {
                        // console.log("daaaa : ",row[i])
                        this.selectedCheck.push(row[i].value);
                    }
                }
            }
        },
        uncheckall() {
            // console.log("uncek : ", this.uncheckall);
            for (var obj in this.uncheckall) {
                var uncek = this.uncheckall[obj];
                var is_data = this.checkall.indexOf(uncek);
                if (is_data != "-1") {
                    this.checkall.splice(is_data, 1);
                }
                // console.log("uncek : ",this.checkall.indexOf(uncek))
            }
        },
        t_form(val) {
            this.selectedCheck = [];
            this.checkall = [];
            this.cardCheckbox = [];
            this.t_formReset = true;

            // console.log("form select : ",val)
            // console.log("form op  : ",this.g_all[val.value])
            // console.log("selected cek cek : ",this.selectedCheck)
            if (val != null) {
                this.is_bmkg_entry = val.value;
                if (this.is_kab_selected != "") {
                    // console.log("kab is : ",this.is_kab_selected)
                    this.listStasiun(this.is_kab_selected);
                } else if (this.is_prov_selected != "") {
                    this.listStasiunProv(this.is_prov_selected);
                } else if (this.is_balai_selected != "") {
                    this.listStasiunReg(this.is_balai_selected);
                }

                var data = [];
                for (var obj in this.g_all[val.value]) {
                    var rowData = {
                        value: obj,
                        text: this.g_all[val.value][obj].label,
                    };
                    data.push(rowData);
                }

                var temp_cols_card = 3;
                var leng_cols = this.g_all[val.value].length;
                if (leng_cols < 2) {
                    temp_cols_card = 12;
                } else if (temp_cols_card < 3) {
                    temp_cols_card = 6;
                } else {
                    temp_cols_card = 4;
                }
                this.temp_cols = temp_cols_card;
                // console.log(val.value)
                // console.log(this.g_all[val.value])
                // console.log(this.g_all)
                this.arr_group = this.g_all[val.value];
                this.group_opt = data;
                this.group = data; // AN Default value
                this.type_object = val.value;
                this.form_name_exp_pdf = val;
                // console.log("val : ",val.value);
                // console.log("text  : ",val.text);
                if (val.value == "FormHujan") {
                    this.form_name_exp_xls = "Data " + val.text;
                } else if (val.value == "FormPerawanan") {
                    this.form_name_exp_xls = "Data Perawanan";
                } else {
                    this.form_name_exp_xls = "Form " + val.text;
                }
                this.form_name_exp_pdf = {
                    value: val.value,
                    text: this.form_name_exp_xls,
                };
            } else {
                this.group = "";
                this.group_opt = []; // AN Clear option too
            }
            //   if(val.value == "lamapenyinaran"){
            //     // this.group_opt = this.g_lamapenyinaran
            //     var data = []
            //     for(var obj in this.g_lamapenyinaran){
            //       var rowData = {
            //         value : obj,
            //         text :this.g_lamapenyinaran[obj].label
            //       }
            //       data.push(rowData)
            //     }
            //     this.group_opt = data
            //     // console.log(this.g_lamapenyinaran)
            //   }else if(val.value == "aktinograph"){
            //     var data = []
            //     for(var obj in this.g_aktinograph){
            //       var rowData = {
            //         value : obj,
            //         text :this.g_aktinograph[obj].label
            //       }
            //       data.push(rowData)
            //     }
            //     this.group_opt = data
            //   }else if(val.value == "thermohigograph"){
            //     var data = []
            //     for(var obj in this.g_thermohigrograph){
            //       var rowData = {
            //         value : obj,
            //         text :this.g_thermohigrograph[obj].label
            //       }
            //       data.push(rowData)
            //     }
            //     this.group_opt = data
            //   }else if(val.value == "intensitashujan"){
            //     var data = []
            //     for(var obj in this.g_intensitashujan){
            //       var rowData = {
            //         value : obj,
            //         text :this.g_intensitashujan[obj].label
            //       }
            //       data.push(rowData)
            //     }
            //     this.group_opt = data
            //   }else if(val.value == "barograph"){
            //     var data = []
            //     for(var obj in this.g_barograph){
            //       var rowData = {
            //         value : obj,
            //         text :this.g_barograph[obj].label
            //       }
            //       data.push(rowData)
            //     }
            //     this.group_opt = data
            //   }else if(val.value == "kelambabantanah"){
            //     var data = []
            //     for(var obj in this.g_kelambabantanah){
            //       var rowData = {
            //         value : obj,
            //         text :this.g_kelambabantanah[obj].label
            //       }
            //       data.push(rowData)
            //     }
            //     this.group_opt = data
            //   }
            // }
        },
        regions(val) {
            if (val != null || val == "") {
                if (this.is_bmkg_entry == "" || this.is_bmkg_entry == undefined) {
                    this.$swal({
                        title: "ERROR",
                        text: "Silahkan Isi Form ",
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-danger",
                        },
                        buttonsStyling: false,
                    });
                    this.regions = null;
                    return;
                }
                this.is_balai_selected = val.value;
                this.listPropinsi(val.value);
                this.listStasiunReg(val.value);
                this.propinsi = { value: "", text: "Semua Propinsi" };
            } else {
                this.propinsi_opt = [];
                this.propinsi = "";
                this.kabupaten_opt = [];
                this.kabupaten = "";
                this.stasiun_opt = [];
                this.stasiun = "";
            }
        },
        propinsi(val) {
            // console.log("prov val : ", val);
            if (val != null || val == "") {
                this.is_prov_selected = val.value;
                this.listKabupaten(val.value);
                this.listStasiunProv(val.value);
                this.kabupaten = { value: "", text: "Semua Kota/Kabupaten" };
            } else {
                this.kabupaten_opt = [];
                this.kabupaten = "";
                this.stasiun_opt = [];
                this.stasiun = "";
            }
        },
        kabupaten(val) {
            // console.log("kab val : ", val);
            if (val != null || val == "") {
                this.is_kab_selected = val.value;
                this.listStasiun(val.value);
                this.stasiun = [{ value: "", text: "Semua Stasiun" }];
            } else {
                this.stasiun_opt = [];
                this.stasiun = "";
            }
        },
        stasiun: {
            handler(newValue, oldValue) {
                const onlyNew = newValue ? newValue.filter((x) => !oldValue?.includes(x)) : [];

                // Check contain semua stasiun in both state
                const indexAllNew = onlyNew.findIndex((s) => s.value === "");
                const indexAllOld = oldValue.findIndex((s) => s.value === "");
                if (indexAllNew > -1) {
                    this.stasiun = [onlyNew[indexAllNew]];
                } else if (indexAllOld > -1 && newValue.length > 1) {
                    // prevval ada semua stasiun dan data yang baru ada atleast 1 stasiun baru
                    this.stasiun.splice(indexAllOld, 1);
                }
            },
            deep: true,
        },
        t_mkg(val) {
            if (val?.value == "meteo") {
                this.t_form_opt = this.t_from_m;
            } else if (val?.value == "klimat") {
                this.t_form_opt = this.t_from_k;
            } else if (val?.value == "geo") {
                this.t_form_opt = this.t_from_g;
            } else {
                this.t_form_opt = [];
            }
            this.t_form = null; // ""; give null to match to t_form clear function
            // this.group = ""; // this already cleared in t_form
        },
        group: {
            handler(newValue, oldValue) {
                const toadd = newValue ? newValue.filter((x) => !oldValue?.includes(x)) : [];
                const toremove = oldValue ? oldValue.filter((x) => !newValue?.includes(x)) : [];

                // Determine size of column
                const leng_cols_current = newValue?.length || 0;
                if (leng_cols_current < 3) {
                    this.col_card = 6;
                } else {
                    this.col_card = 4;
                }

                this.showParamaterGroup(toadd);
                this.hideParamaterGroup(toremove);

                // ReSort
                this.cardCheckbox = this.cardCheckbox.sort((a, b) => a.index_order - b.index_order);

                // If after form reset, set all paramm
                if (this.t_formReset) {
                    this.t_formReset = false;
                    this.checkAllGroups();
                }

                if (newValue?.length > 0) {
                    this.sumbitShow = true;
                } else {
                    this.sumbitShow = false;
                }
            },
            deep: true,
        },
    },
    onClosePopup2() {
        this.popoverShow2 = false;
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style scoped>
.pad1 {
    padding-right: 0px;
}

.pad2 {
    padding-right: 0px;
    padding-left: 0px;
}
</style>
